import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { getOrdersList } from "../../services/api";
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import { useDispatch, useSelector } from 'react-redux';
import OrdersList from "../orders-list/OrdersList";
import { getAffiliateUsersList } from "../../services/api";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as utils from "../../utils";
import { globalConfig } from "../../GlobalConfig";
import { getCurrentTimestamp } from "../../utils/reuse.js";

import "../users/Users.scss";
const Orders = () => {
    const appName = process.env?.REACT_APP_NAME;
    const timeStamp = getCurrentTimestamp()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);
    const [orders, setOrders] = useState([]);
    const toast = useRef();
    const [loading, setLoading] = useState(false);

    const [sortOrder, setSortOrder] = useState(-1);
    const [sortField, setSortField] = useState("createdTs");
    const [sortKey, setSortKey] = useState("-createdTs");

    const [pageCount] = useState(globalConfig.pageCount);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pageCount + 1);
    const [last, setLast] = useState(true);
    const [paging, setPaging] = useState(false);
    const [ordersData, setOrdersData] = useState(true);
    const [listingType, setListingType] = useState('list');

    const affiliateEmail = ""
    const affiliateStatus = ""
    const affiliatePhone = ""
    const affiliateName = ""

    const nVal = null;
    const [customer, setCustomer] = useState('');
    const [orderId, setOrderId] = useState('');
    const [affiliateUser, setAffiliateUser] = useState('');
    const [purchaseDate, setPurchaseDate] = useState(nVal);
    const [purchaseDateFrom, setPurchaseDateFrom] = useState(nVal);
    const [purchaseDateTo, setPurchaseDateTo] = useState(nVal);
    const [paymentGateway, setPaymentGateway] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [orderStatus, setOrderStatus] = useState([]);
    const [providerName, setProviderName] = useState([]);
    const [affiliateNames, setAffiliateNames] = useState([])
    const [customerName, setCustomerName] = useState('')

    const convertToCSV = (data, headers) => {
        const headerRow = headers.map((header) => header.title);
        const csvContent = headerRow.join(',') + '\n';

        const csvRows = data.map((row) =>
            headers
                .map((header) => {
                    const value = row[header.id];
                    return typeof value === 'string' && value.includes(',')
                        ? `"${value}"`
                        : value;
                })
                .join(',')
        );

        return csvContent + csvRows.join('\n');
    };

    const handleExportCSV = useCallback((orderData) => {
        const customHeaders = [
            { id: 'orderId', title: 'Order ID' },
            { id: 'providerName', title: 'Provider Name' },
            { id: 'customer', title: 'Customer' },
            { id: 'affiliateUser', title: 'Affiliate Name' },
            { id: 'purchasedDate', title: 'Purchased On' },
            { id: 'currencySymbol', title: 'Currency' },
            { id: 'price', title: 'Total Price' },
            { id: 'paymentMethod', title: 'Payment Method' },
            { id: 'paymentStatus', title: 'Payment Status' },
            { id: 'orderStatus', title: 'Order Status' },
            { id: 'discountValue', title: 'Voucher Value' },
            { id: 'discountPercentage', title: 'Monetary Discount' },
            { id: 'email', title: 'Email' },
            { id: 'promoCode', title: 'Voucher Code' },
            { id: 'priceBeforeDiscount', title: 'Display Price' },
            {id:  'bundleProviderPrice', title: 'Bundle Provider Price'},
            {id:  'countries', title: 'Country Name / Region'},
            {id: "bundleProviderID", title: 'Bundle Provider ID'},
            {id: "paymentMethodId", title: 'Payment Method ID'}
        ];
        const csvData = convertToCSV(orderData, customHeaders);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `bo.${appName}-orders-${timeStamp}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [appName, timeStamp]);

    const ordersRoutes = location.pathname.includes('/orders') && params.userid ? [
        {
            label: 'Orders',
            command: () => { navigate('/orders') }
        },
        {
            label: customerName ? customerName : params.userid,
            disabled: true
        }
    ] : [{
        label: 'Orders',
        command: () => { navigate('/orders') }
    }]
    const items = location.pathname.includes('/user-orders') ?  [
        {
            label: 'Customers',
            command: () => { navigate('/users') }
        },
        {
            label: customerName ? customerName : params.userid,
            disabled: true
        }
    ]:ordersRoutes;
    useEffect(() => {
        setOffset(0);
        setLimit(pageCount + 1);
        setPage(1);
        setLast(true)
    }, [pageCount, params.userid])

    const home = { icon: 'pi pi-home', url: '/' }

    const getAllAffiliateUsers = useCallback(async () => {
            const sortOrderVal = (sortOrder === 1) ? "asc" : "desc";
            const obj = {
                offset: 0,
                limit: 1000,
                sortField: sortField,
                sortOrder: sortOrderVal,
                name: affiliateName,
                email: affiliateEmail,
                phone: affiliatePhone,
                status: affiliateStatus,
            };
            getAffiliateUsersList(obj, headers, dispatch, response => {
                if (response.result === 'SUCCESS') {
                    const list = response.data;
                    const affiliateName = list.map(x => x.name)
                    setAffiliateNames(affiliateName)
                    const result = list !== null ? list.slice(0, pageCount) : [];
                    if (result.length > 0) {
                        setLast(list.length <= pageCount);
                        setPaging(true);
                    } 
                } else {
                    const error = response.error;
                    toast.current?.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
                }
            })
    }, [ sortOrder, sortField, affiliateEmail, affiliatePhone, headers, dispatch, pageCount, affiliateStatus, affiliateName])


    useEffect(() => {
        getAllAffiliateUsers()
    }, [getAllAffiliateUsers]);

    const getOrders = useCallback(async () => {
        if (ordersData) {
            setLoading(true);

            const obj = {
                listingType: listingType,
                offset: offset,
                limit: limit,
                sortField: sortField,
                sortOrder: (sortOrder === 1) ? "asc" : "desc",
                name: customer,
                orderId: orderId,
                providerName: providerName.join(','),
                affiliateName: affiliateUser,
                paymentGateway: paymentGateway.join(','),
                paymentStatus: paymentStatus.join(','),
                orderStatus: orderStatus.join(','),
                purchaseDateFrom: (purchaseDateFrom !== nVal) ? purchaseDateFrom : '',
                purchaseDateTo: (purchaseDateTo !== nVal) ? purchaseDateTo : '',
            };
            if(params.userid){
                obj.userId = params.userid;
            }
            getOrdersList(obj, headers, dispatch, response => {
                if (response.result === 'SUCCESS') {
                    if (response.data === nVal) {
                        setOrders([]);
                        setLast(true);
                        setPaging(false);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'No records found' });
                    } else {
                        const list = response.data.orders;
                        if(params.userid){
                            const userDetails = response.data?.userDetails;
                            const userName = userDetails?.firstName + ' ' + userDetails?.lastName;
                            setCustomerName(userName);
                        }

                        const result = list !== nVal ? list.slice(0, pageCount) : [];
                        if (result.length > 0) {
                            setLast(list.length <= pageCount);
                            if (listingType === "export") {
                                const exportList = list.map(data => {
                                    data.customer = data.firstName + (data.lastName && " " + data.lastName);
                                    data.affiliateUser = (data?.affiliateFirstName ? data?.affiliateFirstName : '') + (data?.affiliateLastName ? ' ' + data?.affiliateLastName : '');
                                    data.purchasedDate = utils.modifyDateTime(data.createdTs)
                                    const priceWithSymbol = utils.setPrice(data.totalOrderAmount)
                                    data.price = priceWithSymbol.substring(1);
                                    data.currencySymbol = priceWithSymbol.charAt(0);
                                    data.paymentGateway = (data.paymentGateway === "Stripe") ? "Debit / Credit Card" : data.paymentGateway;
                                    data.discountValue = data?.discountValue || 0
                                    data.discountPercentage = data?.discount || 0
                                    data.email = data?.email || "-"
                                    data.promoCode = data?.promoCode || "-"
                                    data.priceBeforeDiscount = data?.priceBeforeDiscount || 0
                                    data.bundleProviderPrice = data?.bundleProviderPrice || 0
                                    data.paymentMethodId = data?.paymentMethodId || "-"
                                    const countriesData = data?.dataPlans
                                    ?.flatMap(plan => plan?.countries || [])
                                    ?.map(country => ({
                                        countryName: country?.country?.name,
                                        region: country?.country?.region
                                    })) || [];
                                    const formattedCountries = countriesData.map(names => {
                                        const country = names.countryName || "-";
                                        const region = names.region || "-";
                                        return `${country} / ${region}`;
                                    }).join(', ');
                                    const providerID = data?.dataPlans
                                    ?.flatMap(bundle => bundle.providerBundleID || "-") || "-"   
                                    return {                                    
                                        orderId: data.orderId,
                                        customer: data.customer,
                                        providerName: data.providerName,
                                        affiliateUser: data.affiliateUser,
                                        purchasedDate: data.purchasedDate,
                                        price: data.price,
                                        currencySymbol: data.currencySymbol,
                                        paymentMethod: data.paymentGateway,
                                        paymentStatus: data.paymentStatus,
                                        orderStatus: data.orderStatus,
                                        discountValue: data.discountValue,
                                        discountPercentage: data.discountPercentage,
                                        email: data.email,
                                        promoCode: data.promoCode,
                                        priceBeforeDiscount: data.priceBeforeDiscount,
                                        bundleProviderPrice: data.bundleProviderPrice,
                                        paymentMethodId: data.paymentMethodId,
                                        countries: formattedCountries,
                                        bundleProviderID: providerID
                                    };
                                })
                                handleExportCSV(exportList)
                            } else {
                                result.map(data => {
                                    data.username = data.firstName + (data.lastName && " " + data.lastName);
                                    data.affiliateUser = (data?.affiliateFirstName ? data?.affiliateFirstName : '') + (data?.affiliateLastName ? ' ' + data?.affiliateLastName : '');
                                    data.createdTs = data.createdTs ? data.createdTs : '-'
                                    data.paymentGateway = (data.paymentGateway === "Stripe") ? "Debit / Credit Card" : data.paymentGateway;
                                    return nVal
                                })
                                setOrders(result);
                                setPaging(true);
                            }
                        } else {
                            setOrders([]);
                            setLast(true);
                            setPaging(false);
                            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'No records found' });
                        }
                    }
                }
                else {
                    setOrders([]);
                    setLast(true);
                    setPaging(false);
                    const error = response.error;
                    toast.current?.show({ severity: error.severity, summary: 'Error', detail: (error?.errorMsg) ? error?.errorMsg : error?.summary })
                }
                setLoading(false);
            })
        }
    }, [ordersData, listingType, offset, limit, sortField, sortOrder, customer, orderId, providerName, affiliateUser, paymentGateway, paymentStatus, orderStatus, purchaseDateFrom, purchaseDateTo, headers, dispatch, params.userid, pageCount, handleExportCSV])
    
    useEffect(() => {
        getOrders()
    }, [getOrders])

    const propsObj = {
        screen: params.userid ? 'user-orders' : 'orders',
        orders: orders,
        toast: toast,
        items: items,
        home: home,
        loading: loading,
        nVal: nVal,
        last: last,
        paging:paging,
        setPaging:setPaging,
        setOrdersData: setOrdersData,
        page: page,
        setPage: setPage,
        pageCount: pageCount,
        setLimit: setLimit,
        setOffset: setOffset,
        setSortOrder: setSortOrder,
        sortField: sortField,
        setSortField: setSortField,
        sortKey: sortKey,
        setSortKey: setSortKey,
        setListingType: setListingType,
        customer: customer,
        setCustomer: setCustomer,
        orderId: orderId,
        setOrderId: setOrderId,
        affiliateUser: affiliateUser,
        setAffiliateUser: setAffiliateUser,
        purchaseDate: purchaseDate,
        setPurchaseDate: setPurchaseDate,
        purchaseDateFrom: purchaseDateFrom,
        setPurchaseDateFrom: setPurchaseDateFrom,
        purchaseDateTo: purchaseDateTo,
        setPurchaseDateTo: setPurchaseDateTo,
        paymentGateway: paymentGateway,
        setPaymentGateway: setPaymentGateway,
        paymentStatus: paymentStatus,
        setPaymentStatus: setPaymentStatus,
        orderStatus: orderStatus,
        setOrderStatus: setOrderStatus,
        providerName: providerName,
        setProviderName: setProviderName,
        affiliateNames: affiliateNames

    }

    return (
        <>
            <div className="main">
                <div className="layout-sidebar">
                    <AdminHeader />
                </div>
                <div className="layout-content-wrapper">
                    <OrdersList {...propsObj} />
                    <AdminFooter />
                </div>
            </div>
        </>
    );
};

export default Orders;