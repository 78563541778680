import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import { getUsersList } from "../../services/api";
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import "../users/Users.scss"; // CSS
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { globalConfig } from "../../GlobalConfig";
import { getAffiliateUsersList } from "../../services/api";
import { useFormik } from "formik";
import * as Yup from 'yup';
import * as utils from '../../utils';
import { getCurrentTimestamp } from "../../utils/reuse.js";

const Users = () => {
    const appName = process.env?.REACT_APP_NAME;
    const timeStamp = getCurrentTimestamp()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const emails = params.get('email');
    const customeremail = emails ? emails : ""
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);

    const [sortOrder, setSortOrder] = useState(-1);
    const [sortField, setSortField] = useState("name");
    const [sortKey, setSortKey] = useState("-name");

    const [users, setUsers] = useState([]);
    const toast = useRef();
    const [loading, setLoading] = useState(true);

    const [pageCount] = useState(globalConfig.pageCount);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pageCount + 1);
    const [last, setLast] = useState(true);
    const [paging, setPaging] = useState(false);
    const [userName, setUserName] = useState("");
    const [userPhone, setUserPhone] = useState('');
    const [affiliateUser, setAffiliateUser] = useState('');
    const [affiliateNames, setAffiliateNames] = useState([])
    const [email, setEmail] = useState(customeremail || '');
    const [userEmail, setUserEmail] = useState(email || '');
    const [exportLoading, setExportLoading] = useState(false)

    const affiliateEmail = ""
    const affiliateStatus = ""
    const affiliatePhone = ""
    const affiliateName = ""

    const [filterOpen, setFilterOpen] = useState(false);
    const toggleFilter = () => setFilterOpen(!filterOpen);

    const onSortChange = (value) => {
        if (value.indexOf("!") === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        } else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
    };

    const getAllAffiliateUsers = useCallback(async () => {
        setLoading(true);
        const sortOrderVal = (sortOrder === 1) ? "asc" : "desc";
        const obj = {
            offset: 0,
            limit: 1000,
            sortField: sortField,
            sortOrder: sortOrderVal,
            name: affiliateName,
            email: affiliateEmail,
            phone: affiliatePhone,
            status: affiliateStatus,
        };
        getAffiliateUsersList(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const list = response.data;
                const affiliateName = list.map(x => x.name)
                setAffiliateNames(affiliateName)
                const result = list !== null ? list.slice(0, pageCount) : [];
                if (result.length > 0) {
                    setPaging(true);
                } 
            } else {
                const error = response.error;
                toast.current?.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
            setLoading(false);
        })
    }, [ sortOrder, sortField, affiliateEmail, affiliatePhone, headers, dispatch, pageCount, affiliateStatus, affiliateName])


    useEffect(() => {
        getAllAffiliateUsers()
    }, [getAllAffiliateUsers])

    const getAllUsers = useCallback(async () => {
        setLoading(true);
        const sortOrderVal = (sortOrder === 1) ? "asc" : "desc";
        const encodedUserEmail = userEmail ? encodeURIComponent(userEmail) : email ? encodeURIComponent(email) : '';
        const obj = {
            offset: offset,
            limit: limit,
            sortField: sortField,
            sortOrder: sortOrderVal,
            name: userName,
            email: encodedUserEmail,
            phone: userPhone,
            affiliateName: affiliateUser,
        };
        getUsersList(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const list = response.data;
                const result = list !== null ? list.slice(0, pageCount) : [];
                if (result.length > 0) {
                    setLast(list.length <= pageCount);
                    result.map(data => {
                        data.affiliateUser = (data?.affiliateFirstName ? data?.affiliateFirstName : '') + (data?.affiliateLastName ? ' ' + data?.affiliateLastName : '')
                        return null
                    })
                    setUsers(result);
                    setPaging(true);
                } else {
                    setUsers([]);
                    setLast(true);
                    setPaging(false);
                    toast.current.show({ severity: 'error', summary:'Error', detail: 'No records found' });
                }
            } else {
                setUsers([]);
                setLast(true);
                setPaging(false);
                const error = response.error;
                toast.current.show({ severity: error.severity, summary:'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
            setLoading(false);
        })
    }, [sortOrder, offset, limit, sortField, userName, userEmail, userPhone, affiliateUser, headers, dispatch, pageCount, email])


    useEffect(() => {
        getAllUsers()
    }, [getAllUsers])

    const paginate = (currentPage) => {
        setPaging(false);
        setPage(currentPage);
        const offsetVal = (currentPage - 1) * pageCount;
        const limitVal = pageCount + 1;
        setOffset(offsetVal);
        setLimit(limitVal);
    };

    const actions = (rowData) => {
        return (
            <>
            <PR.Button
                icon="pi pi-eye"
                label="View Orders"
                className="view-order"
                onClick={() => navigate('/user-orders/' + rowData.userId)}
            />
            <PR.Button 
                icon= "pi pi-eye"
                className="view-order"
                label="View eSIMs"
                onClick={() => navigate(`/user-esims/${rowData.userId}`, { state: { userName: rowData.userName, userId: rowData.userId } })}
            />
            </>
        );
    };
    
    const exportCSV = (e) => {
        setExportLoading(true)
        e.preventDefault();
    
        const obj = {
            offset: 0,
            limit: 10000,
            sortField: sortField,
            sortOrder: sortOrder === 1 ? "asc" : "desc",
            name: userName,
            email: userEmail ? encodeURIComponent(userEmail) : email ? encodeURIComponent(email) : '',
            phone: userPhone,
            affiliateName: affiliateUser,
        };
    
        getUsersList(obj, headers, dispatch, (response) => {
            if (response.result === 'SUCCESS' && response.data?.length > 0) {
                const exportArray = response.data.map((user) => ({
                    userName: user.userName || '-',
                    email: user.email || '-',
                    phone: user.phone?.callingCode && user.phone?.localPhoneNumber
                        ? `${user.phone.callingCode} ${user.phone.localPhoneNumber}`
                        : user.phone?.localPhoneNumber || '-',
                    affiliateUser: `${user.affiliateFirstName || ''} ${user.affiliateLastName || ''}`.trim() || '-',
                }));
    
                const capitalizeFirstLetter = (str) => {
                    const mappings = {
                        userName: 'Customer Name',
                        email: 'Email ID',
                        phone: 'Phone Number',
                        affiliateUser: 'Affiliate Name',
                    };
                    return mappings[str] || str.charAt(0).toUpperCase() + str.slice(1);
                };
    
                const csvContent =
                    "data:text/csv;charset=utf-8," +
                    Object.keys(exportArray[0]).map(capitalizeFirstLetter).join(",") +
                    "\n" +
                    exportArray
                        .map((entry) =>
                            Object.values(entry)
                                .map((value) => (typeof value === 'string' && (value.includes(' ') || value.includes(',')) ? `"${value}"` : value))
                                .join(",")
                        )
                        .join("\n");
    
                const encodedUri = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", `bo.${appName}-customer-list-${timeStamp}.csv`);
                document.body.appendChild(link);
                link.click();
    
                toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Exported successfully' });
            } else {
                toast.current?.show({ severity: 'warn', summary: 'Warning', detail: 'No data available to export' });
            }
            setExportLoading(false)
        });

    };
    
    
    const itemTemplate = (rowData) => {
        return (
            <div className="col-12 border-0 custom-bg">
                <div className="custom-table-body">
                    <div className="table-grid">
                        <ul className="col-ul sorting-li mobile-col-ul">
                            <li className="userName">
                                <div className="column-label">Customer</div>
                                <div className="column-data">{rowData.userName || '-'}</div>
                            </li>
                            <li className="userEmail">
                                <div className="column-label">Email ID</div>
                                <div className="column-data">{rowData.email || '-'}</div>
                            </li>
                            <li className="userPhone">
                                <div className="column-label">Phone Number</div>
                                <div className="column-data">
                                    {rowData?.phone?.callingCode && rowData?.phone?.callingCode !== "+undefined" && rowData?.phone?.localPhoneNumber
                                    ? `${rowData.phone.callingCode} ${rowData.phone.localPhoneNumber || '-'}`
                                    : rowData?.phone?.localPhoneNumber || '-' }
                                </div>
                            </li>                           
                            <li className="affiliateUser">
                                <div className="column-label">Affiliate Name</div>
                                <div className="column-data">{rowData.affiliateUser || "-"}</div>
                            </li>
                             { appName === 'esimcrew' && <li className="crewId">
                                <div className="column-label">Info</div>
                                <div className="column-data">{rowData?.additionalinfo?.crewID}</div>
                            </li>}
                            <li className="actions-tab">
                                <div className="column-label">Actions</div>
                                <div className="column-data mobile-flex">{actions(rowData)}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    };


    const usersDataHeader = (field, label, selector) => {
        return (
            (sortField !== field) ?
                <>
                    <li onClick={(e) => onSortChange(field)} className={selector}><span>{label}<i className="pi pi-sort-alt"></i></span> </li>
                </> :
                <>
                    {(sortKey === field) ?
                        <>
                            <li onClick={(e) => onSortChange('!' + field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-up-alt"></i></span> </li>
                        </> :
                        <li onClick={(e) => onSortChange(field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-down"></i></span> </li>
                    }
                </>
        );
    }

    const [initialValues] = React.useState({
        searchUserName: userName,
        searchUserEmail: userEmail,
        searchUserPhone: userPhone,
        searchAffiliateUser: affiliateUser
    })

    const validationSchema = Yup.object().shape({
        searchUserName: Yup.string().trim(),
        searchUserEmail: Yup.string().trim().email('Invalid email'),
        searchUserPhone: Yup.string().trim(),
        searchAffiliateUser: Yup.string().trim(),
    })

    const resetUserForm = () => {
        formik.resetForm();
        setUserName('');
        setUserEmail('');
        setUserPhone('');
        setAffiliateUser('');
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
        const url = new URL(window.location);
        url.searchParams.delete('email'); 
        window.history.pushState({}, '', url);
        window.location.reload() 
        setEmail('')
    }

    useEffect(() => {
        const url = new URL(window.location);
        url.searchParams.delete('email'); 
        window.history.pushState({}, '', url); 
        setEmail('')
    }, [setEmail])

    const handleSubmit = (formData) => {
       const trimmedFormData = utils.trimFormData(formData);
       setUserName(encodeURIComponent(trimmedFormData.searchUserName));
       setUserEmail((trimmedFormData.searchUserEmail) || email || "");
       setUserPhone(encodeURIComponent(trimmedFormData.searchUserPhone));
       setAffiliateUser(encodeURIComponent(trimmedFormData.searchAffiliateUser))
       setPage(1);
       setOffset(0);
       setLimit(pageCount + 1);
    }

    const handleAffiliateOptions = (customers = []) => {
        return (Array.isArray(customers) ? customers : [])
            .sort((a, b) => a.localeCompare(b));
    };    

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    return (
        <>
            <div className="main">
                <div className="layout-sidebar">
                    <AdminHeader />
                </div>
                <div className="layout-content-wrapper">
                    <section className="admin-userlist-section">
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <div className="heading-sec">
                                    <div className="flex align-items-center justify-content-between">
                                        <div className="flex align-items-center justify-content-between width-full">
                                            <h2>Customers</h2>
                                        </div>
                                        <div className="right-section flex align-items-center gap-2">
                                            <PR.Button label="Export CSV" className="exportBtn" icon="pi pi-file-export" iconPos="left" onClick={exportCSV} disabled={exportLoading}/>
                                            <PR.Button text className="filter-toggle-button" onClick={toggleFilter}>
                                                <i className={`pi ${filterOpen ? "pi-filter-slash" : "pi-filter-fill"}`}></i>
                                            </PR.Button>
                                        </div>
                                    </div>
                                    <div className={`filter-right mt-5 ${filterOpen ? "mobile-filter-section" : ""}`}>
                                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                            <div className='flex align-items-center mobile-flex'>
                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.InputText placeholder="Customer Name" name="searchUserName" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.searchUserName} autoComplete="off" />
                                                    </span>
                                                    {formik.errors.searchUserName && formik.touched.searchUserName
                                                        ? <div className='error-message'>{formik.errors.searchUserName}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.Dropdown placeholder="Affiliate Name" name="searchAffiliateUser" options={handleAffiliateOptions(affiliateNames)} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.searchAffiliateUser} autoComplete="off" />
                                                    </span>
                                                    {formik.errors.searchAffiliateUser && formik.touched.searchAffiliateUser
                                                        ? <div className='error-message'>{formik.errors.searchAffiliateUser}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input email-input">
                                                    <span>
                                                        <PR.InputText placeholder="Email ID" name="searchUserEmail" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.searchUserEmail}  autoComplete="off" />
                                                    </span>
                                                    {formik.errors.searchUserEmail && formik.touched.searchUserEmail
                                                        ? <div className='error-message'>{formik.errors.searchUserEmail}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.InputText  placeholder="Phone Number" name="searchUserPhone"
                                                        onChange={(e) => utils.handlePhoneChange(e, formik,'searchUserPhone')}
                                                        onBlur={formik.handleBlur} value={formik.values.searchUserPhone}  autoComplete="off" />
                                                    </span>
                                                    {formik.errors.searchUserPhone && formik.touched.searchUserPhone
                                                        ? <div className='error-message'>{formik.errors.searchUserPhone}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.Button label="Search" type='submit' className="searchBtn" />
                                                        <PR.Button label="Reset" type='reset' className="resetBtn" onClick={resetUserForm} />
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="users-data-table card">
                            <div className="card">
                                <div className="custom-table">
                                    <div className="custom-table-header">
                                        <div className="table-grid">
                                            <ul className="col-ul sorting-li">
                                                {usersDataHeader('name', 'Customer', 'userName')}
                                                {usersDataHeader('email', 'Email ID', 'userEmail')}
                                                {usersDataHeader('phone', 'Phone Number', 'userPhone')}
                                                {usersDataHeader('affiliateName', 'Affiliate Name', 'affiliateUser')}
                                                { appName === 'esimcrew' && usersDataHeader('crewId', 'Crew ID', 'crewId')}
                                                <li>Actions</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <PR.DataView
                                        loading={loading}
                                        value={users}
                                        itemTemplate={itemTemplate}
                                    />
                                </div>
                            </div>

                        </div>
                        {users.length > 0 ? (
                            <div className="pagination">
                                <button
                                    type="button"
                                    onClick={() => paginate(page - 1)}
                                    className={paging ? page <= 1 ? "disabled" : "pagination-button" : "disabled"}
                                >
                                    {globalConfig.pagination_Previous}
                                </button>
                                <span className="active"> {page} </span>
                                <button
                                    type="button"
                                    onClick={() => paginate(page + 1)}
                                    className={paging ? last ? "disabled" : "pagination-button" : "disabled"}
                                >
                                    {globalConfig.pagination_Next}
                                </button>
                            </div>
                        ) : (
                            <></>
                        )}
                        <PR.Toast ref={toast} position='top-right' />
                    </section>
                    <AdminFooter />
                </div>
            </div>
        </>
    );
};

export default Users;

