import React, { useState,useMemo } from 'react';
import * as PR from "../../../prime-modules/index";
import "./Affiliate.scss";
import { useFormik } from 'formik';
import { uploadAssetsApi } from "../../../services/api"
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { trimFormData, validateTextField } from '../../../utils';
import { getAllAssets } from '../../../genericFunctions/getAssets';
import ImagePreview from '../generic/imagePreview';
import { uploadWidthHeightImage } from '../../../utils/reuse';

const Affiliate = ({assets, disableSave}) => {
    const dispatch = useDispatch();
    const toast = React.useRef(null)
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);

    const [selectedMainImage, setSelectedMainImage] = useState(null);
    const [instantActivation,setInstantActivation] = useState(null)
    const [dashboardImage,setDashboardImage] = useState(null)
    const [investmentImage, setInvestmentImage] = useState(null);
    const [affiliateImage, setAffiliateImage] = useState(null);
    const [stepImage1, setStepImage1] = useState(null);
    const [stepImage2, setStepImage2] = useState(null);
    const [stepImage3, setStepImage3] = useState(null);
    const [gettingStartedBgImage, setGettingStartedBgImage] = useState(null);


    const validationSchema = Yup.object().shape({
        AFF0002: validateTextField(7, 40, 'Header'),
        AFF0003: validateTextField(15, 100, 'Description'),
        AFF0004: validateTextField(7, 20, 'Button'),
        AFF0005: validateTextField(7, 20, 'Panel1 Header'), 
        AFF0006: validateTextField(35, 120, 'Panel1 Description'),
        AFF0008: validateTextField(7, 20, 'Panel2 Header'),
        AFF0009: validateTextField(35, 120, 'Panel2 Description'),
        AFF0011: validateTextField(7, 30, 'Panel3 Header'),
        AFF0012: validateTextField(35, 120, 'Panel3 Description'),
        AFF0015: validateTextField(15, 45, 'Header'),
        AFF0016: validateTextField(200, 400, 'Description'),
        AFF0017: validateTextField(7, 20, 'Button'),
        AFF0018: validateTextField(40, 100,'Header'),
        AFF0019: validateTextField(80, 150,'Description'),
        AFF0021: validateTextField(15, 70, 'Step1 Description'),
        AFF0023: validateTextField(15,70, 'Step2 Description'),
        AFF0025: validateTextField(15, 70, 'Step3 Description'),
        AFF0026: validateTextField(80, 150, 'Description'),
        AFF0027: validateTextField(30, 65, 'Sales Header'),
        AFF0028: validateTextField(10, 25, 'Header'),
        AFF0029: validateTextField(20, 50, 'Description'),
        AFF0030: validateTextField(25, 50, 'Sub Text'),
        AFF0031: validateTextField(10, 25, 'Header'),
        AFF0032: validateTextField(80, 300, 'Description'),
        AFF0033: validateTextField(10, 25, 'Header'),
        AFF0034: validateTextField(80, 300, 'Description'),
        AFF0035: validateTextField(10, 25, 'Header'),
        AFF0036: validateTextField(80, 300, 'Description'),
        AFF0037: validateTextField(7, 20, 'Button'),
        AFF0039: validateTextField(40, 75, 'Header'),
        AFF0040: validateTextField(7, 25, 'Button'),
        AFF0041: validateTextField(10, 25, 'Button')
    });

    const handleSubmit = async (data) => {  
        const formData = new FormData();
        const trimmedFormData = trimFormData(data);
        formData.append('screen', 'affiliate');
        for (const field in trimmedFormData) {
            if (trimmedFormData.hasOwnProperty(field)) {
                const value = trimmedFormData[field];
                if (value && typeof value === 'string' && !(value.startsWith('iVBORw0KGgoAAAANSUhEUg') || value.startsWith('/9j/4AAQSkZJRgA'))) {
                    formData.append(field, value);
                }
            }           
        }
        const getCMSResponse = (response) => {
            if (response.result === 'SUCCESS') {
                if(response.data === 'success'){
                  toast.current.show({ severity: 'success', summary: 'Success', detail: ' Updated successfully' });
                }
                getAllAssets(toast, dispatch, headers);
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        };
        uploadAssetsApi(formData, headers, dispatch, getCMSResponse);
    }


    const formik = useFormik({
        initialValues: assets,
        validationSchema,
        enableReinitialize: true, // Reinitialize form when assets change
        onSubmit: handleSubmit,
    });

    return (
        <div className='home'>
            <div className="card">
                {/* <h2>Home Screen Settings</h2> */}
                <form onSubmit={formik.handleSubmit}>
                    <div className="imageFormat mb-4 ">
                        <strong>(Image Size Should not exceed 1MB)</strong>
                    </div>
                    {/* Main Background Image */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-3'>
                            <label htmlFor="AFF0001" className="cmsLabels">Main Background Image <span className='imageFormat'>(png, jpeg, 1920W X 949H)</span></label>
                        </div>
                        <div className="col-4">
                            <div className="file-upload-section">
                                <PR.InputText
                                    type="file"
                                    name="AFF0001"
                                    className="file-upload-input"
                                    accept="image/jpeg"
                                    onChange={async(event) => {
                                        const file = event.currentTarget.files[0];
                                        setSelectedMainImage(file);
                                        formik.handleChange(event);
                                        uploadWidthHeightImage(file, "AFF0001", toast, dispatch, headers, 'affiliate')
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {selectedMainImage && formik.touched.AFF0001 && formik.errors.AFF0001 ? (
                                <div className='error-msg'>{formik.errors.AFF0001}</div>
                            ) : null}
                        </div>
                        <div className="col-12">
                            <div className="grid grid-nogutter">
                                <div className="col-12">
                                    <div className="label-input-wrapper">
                                        <div className="col-3 invisible-label">
                                            {selectedMainImage && (<p>Main Background Image :</p>)}</div>
                                        <div>
                                            <ImagePreview formikError={formik.errors.AFF0001} defaultImage={assets?.AFF0001} newImage={selectedMainImage}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Top Header */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-3'>
                            <label htmlFor="AFF0002" className="cmsLabels">Top Header</label>
                        </div>
                        <div className="col-4">
                            <div className="card file-upload-section">
                                <PR.InputText
                                    id="AFF0002"
                                    name="AFF0002"
                                    aria-describedby="AFF0002"
                                    value={formik.values.AFF0002}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.AFF0002 && formik.errors.AFF0002 ? (
                                <div className='error-msg'>{formik.errors.AFF0002}</div>
                            ) : null}
                        </div>
                    </div>

                    {/* Top Header Description */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-3'>
                            <label htmlFor="AFF0003" className="cmsLabels">Top Header Description</label>
                        </div>
                        <div className="col-4">
                            <div className="card file-upload-section">
                                <PR.InputText
                                    id="AFF0003"
                                    name="AFF0003"
                                    aria-describedby="AFF0003"
                                    value={formik.values.AFF0003}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.AFF0003 && formik.errors.AFF0003 ? (
                                <div className='error-msg'>{formik.errors.AFF0003}</div>
                            ) : null}
                        </div>
                    </div>
                    
                    {/* Button Name */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-3'>
                            <label htmlFor="AFF0004" className="cmsLabels">Button Name</label>
                        </div>
                        <div className="col-4">
                            <div className="card file-upload-section">
                                <PR.InputText
                                    id="AFF0004"
                                    name="AFF0004"
                                    aria-describedby="AFF0004"
                                    value={formik.values.AFF0004}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.AFF0004 && formik.errors.AFF0004 ? (
                                <div className='error-msg'>{formik.errors.AFF0004}</div>
                            ) : null}
                        </div>
                    </div>

                    <PR.Divider />

                     {/* Panels Section */}
                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <label htmlFor="panelsSection"><h3 className="mb-3 mt-1">Panels Section</h3></label>
                            <div className="card">
                                <div className='grid'>
                                    <div className="col-2"></div>
                                    <div className="col">
                                        <div className="col-12 text-center">
                                            <h4 className="mb-2 mt-0">Panel-1</h4>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="col-12 text-center">
                                            <h4 className="mb-2 mt-0">Panel-2</h4>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="col-12 text-center">
                                            <h4 className="mb-2 mt-0">Panel-3</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="label-input-wrapper align-items-center">
                                    <div className="col-2">
                                        <div className="col-12 mb-4">
                                            <label htmlFor="panelHeader" className="cmsLabels">Header</label>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <label htmlFor="panelDescription" className="cmsLabels">Description</label>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="panelImage" className="cmsLabels">Image </label>
                                        </div>
                                    </div>
                                    {/* Panel-1 Section */}
                                    <div className="col">
                                        <div className="panel-card">
                                            {/* Panel-1 Header */}
                                            <div className="grid grid-nogutter align-items-center mb-3">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="AFF0005"
                                                            name="AFF0005"
                                                            aria-describedby="AFF0005"
                                                            value={formik.values.AFF0005}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0005 && formik.errors.AFF0005 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0005}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        {/* Panel-1 Description */}
                                        <div className="grid grid-nogutter align-items-center mb-3">
                                            <div className="col-12">
                                                <div className="file-upload-section">
                                                    <PR.InputTextarea
                                                        autoResize
                                                        id="AFF0006"
                                                        name="AFF0006"
                                                        aria-describedby="AFF0006"
                                                        value={formik.values.AFF0006}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {formik.touched.AFF0006 && formik.errors.AFF0006 ? (
                                                    <div className='error-msg'>{formik.errors.AFF0006}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Panel-1 Image */}
                                        <div className="grid grid-nogutter align-items-center">
                                            <div className="col-12">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        type="file"
                                                        name="AFF0007"
                                                        className="file-upload-input"
                                                        accept="image/png, image/jpeg"
                                                        onChange={async(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            setInstantActivation(file);
                                                            formik.handleChange(event);
                                                            uploadWidthHeightImage(file, "AFF0007", toast, dispatch, headers, 'affiliate')
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {instantActivation && formik.touched.AFF0007 && formik.errors.AFF0007 ? (
                                                    <div className='error-msg'>{formik.errors.AFF0007}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <span className='imageFormat'>(png, jpeg, 53W X 70H)</span>
                                        </div>
                                    </div>

                                    {/* Panel-2 Section */}
                                    <div className="col">
                                        <div className="panel-card">
                                            {/* Panel-2 Header */}
                                            <div className="grid grid-nogutter align-items-center mb-3">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="AFF0008"
                                                            name="AFF0008"
                                                            aria-describedby="AFF0008"
                                                            value={formik.values.AFF0008}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0008 && formik.errors.AFF0008 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0008}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Panel-2 Description */}
                                            <div className="grid grid-nogutter align-items-center mb-3">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputTextarea
                                                            autoResize
                                                            id="AFF0009"
                                                            name="AFF0009"
                                                            aria-describedby="AFF0009"
                                                            value={formik.values.AFF0009}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0009 && formik.errors.AFF0009 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0009}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Panel-2 Image */}
                                            <div className="grid grid-nogutter align-items-center">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            type="file"
                                                            name="AFF0010"
                                                            className="file-upload-input"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                setDashboardImage(file);
                                                                formik.handleChange(event);
                                                                uploadWidthHeightImage(file, "AFF0010", toast, dispatch, headers, 'affiliate')
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {dashboardImage && formik.touched.AFF0010 && formik.errors.AFF0010 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0010}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <span className='imageFormat'>(png, jpeg, 75W X 75H)</span>
                                        </div>
                                    </div>

                                    {/* Panel-3  */}
                                    <div className="col">
                                        <div className="panel-card">
                                            <div className="grid grid-nogutter align-items-center mb-3">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="AFF0011"
                                                            name="AFF0011"
                                                            aria-describedby="AFF0011"
                                                            value={formik.values.AFF0011}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0011 && formik.errors.AFF0011 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0011}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Panel-3 Description */}
                                            <div className="grid grid-nogutter align-items-center mb-3">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputTextarea
                                                            autoResize
                                                            id="AFF0012"
                                                            name="AFF0012"
                                                            aria-describedby="AFF0012"
                                                            value={formik.values.AFF0012}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            style={{minHeight:'58px'}}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0012 && formik.errors.AFF0012 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0012}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Panel-3 Image */}
                                            <div className="grid grid-nogutter align-items-center">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            type="file"
                                                            name="AFF0013"
                                                            className="file-upload-input"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                setInvestmentImage(file);
                                                                formik.handleChange(event);
                                                                uploadWidthHeightImage(file, "AFF0013", toast, dispatch, headers, 'affiliate')
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {investmentImage && formik.touched.AFF0013 && formik.errors.AFF0013 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0013}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <span className='imageFormat'>(png, jpeg, 76W X 76H)</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="label-input-wrapper">
                                    <div className="col-2"></div>
                                    <div className="col">
                                        <div className="grid grid-nogutter">
                                            <div className="col-12">
                                                <ImagePreview formikError={formik.errors.AFF0007} defaultImage={assets?.AFF0007} newImage={instantActivation}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="grid grid-nogutter">
                                            <div className="col-12">
                                                <ImagePreview formikError={formik.errors.AFF0010} defaultImage={assets?.AFF0010} newImage={dashboardImage}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="grid grid-nogutter">
                                            <div className="col-12">
                                                <ImagePreview formikError={formik.errors.AFF0013} defaultImage={assets?.AFF0013} newImage={investmentImage}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PR.Divider />

                     {/* Affiliate Program Section */}
                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <label htmlFor="unlockSection"><h3 className="mb-3 mt-1">Affiliate Program Section</h3></label>
                            <div className="card">
                                <div className="grid">
                                    <div className="col-6">
                                        {/* Seamless Connectivity Phone Image */}
                                        <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className='col-5'>
                                                <label htmlFor="AFF0014" className="cmsLabels"> Image <br/> 
                                                <span className='imageFormat'>(png, jpeg, 542W X 542H)</span></label>
                                            </div>
                                            <div className="col-7">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        type="file"
                                                        name="AFF0014"
                                                        className="file-upload-input"
                                                        accept="image/png, image/jpeg"
                                                        onChange={async(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            setAffiliateImage(file);
                                                            formik.handleChange(event);
                                                            uploadWidthHeightImage(file, "AFF0014", toast, dispatch, headers, 'affiliate')
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {affiliateImage && formik.touched.AFF0014 && formik.errors.AFF0014 ? (
                                                    <div className='error-msg'>{formik.errors.AFF0014}</div>
                                                ) : null}

                                                <ImagePreview formikError={formik.errors.AFF0014} defaultImage={assets?.AFF0014} newImage={affiliateImage}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        {/* Header */}
                                        <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className='col-3'>
                                                <label htmlFor="AFF0015" className="cmsLabels">Header</label>
                                            </div>
                                            <div className="col-9">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        id="AFF0015"
                                                        name="AFF0015"
                                                        aria-describedby="AFF0015"
                                                        value={formik.values.AFF0015}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {formik.touched.AFF0015 && formik.errors.AFF0015 ? (
                                                    <div className='error-msg'>{formik.errors.AFF0015}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Description */}
                                        <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className='col-3'>
                                                <label htmlFor="AFF0016" className="cmsLabels">Description</label>
                                            </div>
                                            <div className="col-9">
                                                <div className="file-upload-section">
                                                    <PR.InputTextarea
                                                        id="AFF0016"
                                                        autoResize
                                                        name="AFF0016"
                                                        aria-describedby="AFF0016"
                                                        value={formik.values.AFF0016}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {formik.touched.AFF0016 && formik.errors.AFF0016 ? (
                                                    <div className='error-msg'>{formik.errors.AFF0016}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Button Name */}
                                        <div className="grid grid-nogutter align-items-center mb-1">
                                            <div className='col-3'>
                                                <label htmlFor="AFF0017" className="cmsLabels">Button Name</label>
                                            </div>
                                            <div className="col-9">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        id="AFF0017"
                                                        name="AFF0017"
                                                        aria-describedby="AFF0017"
                                                        value={formik.values.AFF0017}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {formik.touched.AFF0017 && formik.errors.AFF0017 ? (
                                                    <div className='error-msg'>{formik.errors.AFF0017}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PR.Divider />

                      {/* Steps Section */}
                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <label htmlFor="stepsSection"><h3 className="mb-3 mt-1">Steps Section</h3></label>
                            <div className="card">
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-2'>
                                        <label htmlFor="AFF0018" className="cmsLabels">Header</label>
                                    </div>
                                    <div className="col-5">
                                <div className="card file-upload-section">
                                    <PR.InputText
                                        id="AFF0018"
                                        name="AFF0018"
                                        aria-describedby="AFF0018"
                                        value={formik.values.AFF0018}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    </div>
                                    {formik.touched.AFF0018 && formik.errors.AFF0018 ? (
                                        <div className='error-msg'>{formik.errors.AFF0018}</div>
                                    ) : null}
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-2'>
                                        <label htmlFor="AFF0019" className="cmsLabels">Description</label>
                                    </div>
                                    <div className="col-5">
                                        <div className="card file-upload-section">
                                            <PR.InputTextarea
                                                id="AFF0019"
                                                autoResize
                                                name="AFF0019"
                                                aria-describedby="AFF0019"
                                                value={formik.values.AFF0019}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.AFF0019 && formik.errors.AFF0019 ? (
                                            <div className='error-msg'>{formik.errors.AFF0019}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='grid'>
                                    <div className="col-2"></div>
                                    <div className="col">
                                        <div className="col-12 text-center">
                                            <h4 className="mb-2 mt-0">Step-1</h4>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="col-12 text-center">
                                            <h4 className="mb-2 mt-0">Step-2</h4>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="col-12 text-center">
                                            <h4 className="mb-2 mt-0">Step-3</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="label-input-wrapper align-items-center mb-4">
                                    <div className="col-2">
                                        <div className="col-12 mb-4">
                                            <label htmlFor="panelImage" className="cmsLabels">Image</label>
                                        </div>
                                        <div className="col-12 ">
                                            <label htmlFor="panelDescription" className="cmsLabels">Sub Text</label>
                                        </div>
                                    </div>
                                    {/* Panel-1 Section */}
                                    <div className="col">
                                        <div className="panel-card">                                            
                                            {/* Panel-1 Image */}
                                            <div className="grid grid-nogutter align-items-center">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            type="file"
                                                            name="AFF0020"
                                                            className="file-upload-input"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                setStepImage1(file);
                                                                formik.handleChange(event);
                                                                uploadWidthHeightImage(file, "AFF0020", toast, dispatch, headers, 'affiliate')
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {stepImage1 && formik.touched.AFF0020 && formik.errors.AFF0020? (
                                                        <div className='error-msg'>{formik.errors.AFF0020}</div>
                                                    ) : null}
                                                    {/* Preview Image */}
                                                     <ImagePreview formikError={formik.errors.AFF0020} defaultImage={assets?.AFF0020} newImage={stepImage1}/>
                                                </div>
                                            </div>
                                            <span className='imageFormat'>(png, jpeg, 53W X 32H)</span>
                                            <div className="grid grid-nogutter align-items-center mt-3">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="AFF0021"
                                                            name="AFF0021"
                                                            aria-describedby="AFF0021"
                                                            value={formik.values.AFF0021}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0021 && formik.errors.AFF0021 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0021}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Panel-2 Section */}
                                    <div className="col">
                                        <div className="panel-card">
                                            {/* Panel-2 Image */}
                                            <div className="grid grid-nogutter align-items-center">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            type="file"
                                                            name="AFF0022"
                                                            className="file-upload-input"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                setStepImage2(file);
                                                                formik.handleChange(event);
                                                                uploadWidthHeightImage(file, "AFF0022", toast, dispatch, headers, 'affiliate')
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {stepImage2 && formik.touched.AFF0022 && formik.errors.AFF0022 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0022}</div>
                                                    ) : null}

                                                    {/* Preview Image */}
                                                    <ImagePreview formikError={formik.errors.AFF0022} defaultImage={assets?.AFF0022} newImage={stepImage2}/>
                                                </div>
                                            </div>
                                            <span className='imageFormat'>(png, jpeg, 55W X 55H)</span>
                                            <div className="grid grid-nogutter align-items-center mt-3">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="AFF0023"
                                                            name="AFF0023"
                                                            aria-describedby="AFF0023"
                                                            value={formik.values.AFF0023}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0023 && formik.errors.AFF0023 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0023}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Panel-3  */}
                                    <div className="col">
                                        <div className="panel-card">
                                            {/* Panel-3 Image */}
                                            <div className="grid grid-nogutter align-items-center">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            type="file"
                                                            name="AFF0024"
                                                            className="file-upload-input"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                setStepImage3(file);
                                                                formik.handleChange(event);
                                                                uploadWidthHeightImage(file, "AFF0024", toast, dispatch, headers, 'affiliate')
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {stepImage3 && formik.touched.AFF0024 && formik.errors.AFF0024 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0024}</div>
                                                    ) : null}
                                                    {/* Preview Image */}
                                                    <ImagePreview formikError={formik.errors.AFF0024} defaultImage={assets?.AFF0024} newImage={stepImage3}/>
                                                </div>
                                            </div>
                                            <span className='imageFormat'>(png, jpeg, 55W X 55H)</span>
                                            <div className="grid grid-nogutter align-items-center mt-3">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="AFF0025"
                                                            name="AFF0025"
                                                            aria-describedby="AFF0025"
                                                            value={formik.values.AFF0025}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0025 && formik.errors.AFF0025 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0025}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-2'>
                                        <label htmlFor="AFF0026" className="cmsLabels">Description</label>
                                    </div>
                                    <div className="col-5">
                                        <div className="card file-upload-section">
                                            <PR.InputTextarea
                                                id="AFF0026"
                                                autoResize
                                                name="AFF0026"
                                                aria-describedby="AFF0026"
                                                value={formik.values.AFF0026}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.AFF0026 && formik.errors.AFF0026 ? (
                                            <div className='error-msg'>{formik.errors.AFF0026}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PR.Divider />

                      {/* Sales Section */}
                    <div className="file-upload">
                        <label htmlFor="partnerSection"><h3 className="mb-4 mt-1">Sales Section</h3></label>
                        <div className="card">
                            <div className="grid">
                                <div className="col-12">
                                    <div className="grid grid-nogutter align-items-center mb-4">
                                        <div className='col-2'><label htmlFor="AFF0027"><b>Header</b></label></div>
                                        <div className="col-5">
                                            <div className="file-upload-section">
                                                <PR.InputText
                                                    id="AFF0027"
                                                    name="AFF0027"
                                                    aria-describedby="AFF0027"
                                                    value={formik.values.AFF0027}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            {formik.touched.AFF0027 && formik.errors.AFF0027 ? (
                                                <div className='error-msg'>{formik.errors.AFF0027}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PR.Divider />

                       {/* Getting Started Section */}
                    <div className="file-upload">
                        <div>
                            <label htmlFor="panelIcons"><h3 className="mb-3 mt-1">Getting Started Section</h3></label>
                            <div className="card">
                                <div className="grid">
                                    <div className="col-6">
                                        <div className='benefit-box'>
                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="AFF0028"><b>Header </b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="AFF0028"
                                                            name="AFF0028"
                                                            aria-describedby="AFF0028"
                                                            value={formik.values.AFF0028}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0028 && formik.errors.AFF0028 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0028}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="AFF0029"><b>Description</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="AFF0029"
                                                            name="AFF0029"
                                                            aria-describedby="AFF0029"
                                                            value={formik.values.AFF0029}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0029 && formik.errors.AFF0029 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0029}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="grid grid-nogutter align-items-center mb-2">
                                                <div className='col-4'>
                                                    <label htmlFor="AFF0030"><b>Sub Text </b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="AFF0030"
                                                            name="AFF0030"
                                                            aria-describedby="AFF0030"
                                                            value={formik.values.AFF0030}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0030 && formik.errors.AFF0030 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0030}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Getting Started-1 */}
                                        <div className='benefit-box'>
                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="AFF0031"><b>Header 1</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="AFF0031"
                                                            name="AFF0031"
                                                            aria-describedby="AFF0031"
                                                            value={formik.values.AFF0031}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0031 && formik.errors.AFF0031 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0031}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grid grid-nogutter align-items-center mb-2">
                                                <div className='col-4'>
                                                    <label htmlFor="AFF0032"><b>Description 1</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputTextarea
                                                            autoResize
                                                            id="AFF0032"
                                                            name="AFF0032"
                                                            aria-describedby="AFF0032"
                                                            value={formik.values.AFF0032}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0032 && formik.errors.AFF0032 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0032}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Getting Started-2 */}
                                        <div className='benefit-box'>
                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="AFF0033"><b>Header 2</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="AFF0033"
                                                            name="AFF0033"
                                                            aria-describedby="AFF0033"
                                                            value={formik.values.AFF0033}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0033 && formik.errors.AFF0033 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0033}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grid grid-nogutter align-items-center mb-2">
                                                <div className='col-4'>
                                                    <label htmlFor="AFF0034"><b>Description 2</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputTextarea
                                                            autoResize
                                                            id="AFF0034"
                                                            name="AFF0034"
                                                            aria-describedby="AFF0034"
                                                            value={formik.values.AFF0034}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0034 && formik.errors.AFF0034 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0034}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Getting Started-3 */}
                                        <div className='benefit-box'>
                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="AFF0035"><b>Header 3</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="AFF0035"
                                                            name="AFF0035"
                                                            aria-describedby="AFF0035"
                                                            value={formik.values.AFF0035}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0035 && formik.errors.AFF0035 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0035}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grid grid-nogutter align-items-center mb-2">
                                                <div className='col-4'>
                                                    <label htmlFor="AFF0036"><b>Description 3</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputTextarea
                                                            autoResize
                                                            id="AFF0036"
                                                            name="AFF0036"
                                                            aria-describedby="AFF0036"
                                                            value={formik.values.AFF0036}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.AFF0036 && formik.errors.AFF0036 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0036}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                         </div>
                                         <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className='col-4'>
                                                <label htmlFor="AFF0037"><b>Button Name</b></label>
                                            </div>
                                            <div className="col-8">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        id="AFF0037"
                                                        name="AFF0037"
                                                        aria-describedby="AFF0037"
                                                        value={formik.values.AFF0037}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {formik.touched.AFF0037 && formik.errors.AFF0037 ? (
                                                    <div className='error-msg'>{formik.errors.AFF0037}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className='col-5'>
                                                <label htmlFor="AFF0038" className="cmsLabels">Background Image <span className='imageFormat'>(png, jpeg, 688W X 1012H)</span></label>
                                            </div>
                                            <div className="col-7">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        type="file"
                                                        name="AFF0038"
                                                        className="file-upload-input"
                                                        accept="image/png, image/jpeg"
                                                        onChange={async(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            setGettingStartedBgImage(file);
                                                            formik.handleChange(event);
                                                            uploadWidthHeightImage(file, "AFF0038", toast, dispatch, headers, 'affiliate')
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {gettingStartedBgImage && formik.touched.AFF0038 && formik.errors.AFF0038 ? (
                                                        <div className='error-msg'>{formik.errors.AFF0038}</div>
                                                    ) : null}
                                                    <ImagePreview formikError={formik.errors.AFF0038} defaultImage={assets?.AFF0038} newImage={gettingStartedBgImage}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className='col-5'>
                                                <label htmlFor="AFF0039"><b>Header</b></label>
                                            </div>
                                            <div className="col-7">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        id="AFF0039"
                                                        name="AFF0039"
                                                        aria-describedby="AFF0039"
                                                        value={formik.values.AFF0039}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {formik.touched.AFF0039 && formik.errors.AFF0039 ? (
                                                    <div className='error-msg'>{formik.errors.AFF0039}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className='col-5'>
                                                <label htmlFor="AFF0040"><b>Button Name</b></label>
                                            </div>
                                            <div className="col-7">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        id="AFF0040"
                                                        name="AFF0040"
                                                        aria-describedby="AFF0040"
                                                        value={formik.values.AFF0040}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {formik.touched.AFF0040 && formik.errors.AFF0040 ? (
                                                    <div className='error-msg'>{formik.errors.AFF0040}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className='col-5'>
                                                <label htmlFor="AFF0041"><b>Button Name</b></label>
                                            </div>
                                            <div className="col-7">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        id="AFF0041"
                                                        name="AFF0041"
                                                        aria-describedby="AFF0041"
                                                        value={formik.values.AFF0041}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {formik.touched.AFF0041 && formik.errors.AFF0041 ? (
                                                    <div className='error-msg'>{formik.errors.AFF0041}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-nogutter">
                        <div className="col-2">
                        </div>
                        <div className="col-9">
                            <PR.Button label="Save" type='submit' className="saveBtn mt-2" disabled={disableSave}/>
                        </div>
                    </div>
                </form>
            </div>
            <PR.Toast ref={toast} position="top-right" />
        </div>
    );
};
export default Affiliate;