import React, { useState, useMemo } from "react";
import * as PR from "../../../prime-modules/index";
import "./Affiliate.scss";
import { useFormik } from "formik";
import { uploadAssetsApi } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { trimFormData } from "../../../utils";
import { getAllAssets } from "../../../genericFunctions/getAssets";
import ImagePreview from "../generic/imagePreview";
import { uploadWidthHeightImage } from "../../../utils/reuse";

const AffiliateShop = ({ assets, disableSave }) => {

  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const adminData = useSelector((state) => state.adminAuth.adminSessionData);
  const headers = useMemo(() => {
    return { sessionid: adminData.sessionId };
  }, [adminData.sessionId]);

  const [howItWorksImage, setHowItWorksImage] = useState(null);
  const [benefitImage1, setBenefitImage1] = useState(null);
  const [benefitImage2, setBenefitImage2] = useState(null);
  const [benefitImage3, setBenefitImage3] = useState(null);
  const [benefitImage4, setBenefitImage4] = useState(null);
  const [benefitImage5, setBenefitImage5] = useState(null);
  const [affiliateImage, setAffiliateImage] = useState(null);


  const handleSubmit = async (data) => {
    const formData = new FormData();
    const trimmedFormData = trimFormData(data);
    formData.append("screen", "affiliate-new");
    for (const field in trimmedFormData) {
      if (trimmedFormData.hasOwnProperty(field)) {
        const value = trimmedFormData[field];
        if (
          value &&
          typeof value === "string" &&
          !(
            value.startsWith("iVBORw0KGgoAAAANSUhEUg") ||
            value.startsWith("/9j/4AAQSkZJRgA")
          )
        ) {
          formData.append(field, value);
        }
      }
    }
    const getCMSResponse = (response) => {
      if (response.result === "SUCCESS") {
        if (response.data === "success") {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: " Updated successfully",
          });
        }
        getAllAssets(toast, dispatch, headers);
      } else if (response.result === "FAILED" && response.error) {
        const error = response.error;
        toast.current.show({
          severity: error.severity,
          summary: "Error",
          detail: error.errorMsg ? error.errorMsg : error.summary,
        });
      }
    };
   uploadAssetsApi(formData, headers, dispatch, getCMSResponse);
  };

  const formik = useFormik({
    initialValues: assets,
    //validationSchema,
    enableReinitialize: true, // Reinitialize form when assets change
    onSubmit: handleSubmit,
  });

  return (
    <div className="home">
      <div className="card">
        {/* <h2>Home Screen Settings</h2> */}
        <form onSubmit={formik.handleSubmit}>
          <div className="imageFormat mb-4 ">
            <strong>(Image Size Should not exceed 1MB)</strong>
          </div>
          <>
          <div className="flex"> 
          {/* Top Header 1.1 */}
          <div className="flex col-6 align-items-center mb-4">
            <div className="col-3">
              <label htmlFor="AFFN0001" className="cmsLabels">
                Top Header 1.1
              </label>
            </div>
            <div className="col-7">
              <div className="card file-upload-section">
                <PR.InputText
                  id="AFFN0001"
                  name="AFFN0001"
                  aria-describedby="AFFN0001"
                  value={formik.values.AFFN0001}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.AFFN0001 && formik.errors.AFFN0001 ? (
                <div className="error-msg">{formik.errors.AFFN0001}</div>
              ) : null}
            </div>
          </div>
          {/* Top Header 1.2 */}
          <div className="flex col-6 align-items-center mb-4">
            <div className="col-3">
              <label htmlFor="AFFN0035" className="cmsLabels">
                Top Header 1.2
              </label>
            </div>
            <div className="col-7">
              <div className="card file-upload-section">
                <PR.InputText
                  id="AFFN0035"
                  name="AFFN0035"
                  aria-describedby="AFFN0035"
                  value={formik.values.AFFN0035}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.AFFN0035 && formik.errors.AFFN0035 ? (
                <div className="error-msg">{formik.errors.AFFN0035}</div>
              ) : null}
            </div>
          </div>
          </div>
     
          {/* Top Header Description */}
          <div className="flex align-items-center mb-4">
            <div className="col-3">
              <label htmlFor="AFF0003" className="cmsLabels">
                Top Header Description
              </label>
            </div>
            <div className="col-4">
              <div className="card file-upload-section">
                <PR.InputText
                  id="AFF0003"
                  name="AFF0003"
                  aria-describedby="AFF0003"
                  value={formik.values.AFF0003}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.AFF0003 && formik.errors.AFF0003 ? (
                <div className="error-msg">{formik.errors.AFF0003}</div>
              ) : null}
            </div>
          </div>

          {/* Button Name */}
          <div className="flex align-items-center mb-4">
            <div className="col-3">
              <label htmlFor="AFFN0003" className="cmsLabels">
                Button Name
              </label>
            </div>
            <div className="col-4">
              <div className="card file-upload-section">
                <PR.InputText
                  id="AFFN0003"
                  name="AFFN0003"
                  aria-describedby="AFFN0003"
                  value={formik.values.AFFN0003}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.AFFN0003 && formik.errors.AFFN0003 ? (
                <div className="error-msg">{formik.errors.AFFN0003}</div>
              ) : null}
            </div>
          </div>
         
          <PR.Divider />
          </>
          {/*How it works Section */}
          <>
            <label htmlFor="panelsSection">
              <h3 className="mb-3 mt-1">How it works Section</h3>
            </label>
            <div className="flex">
            {/*Title */}
            <div className="flex col-6 align-items-center mb-4">
              <div className="col-2">
                <label htmlFor="AFFN0004" className="cmsLabels">
                  Header
                </label>
              </div>
              <div className="col-8">
                <div className="card file-upload-section">
                  <PR.InputText
                    id="AFFN0004"
                    name="AFFN0004"
                    aria-describedby="AFFN0004"
                    value={formik.values.AFFN0004}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.AFFN0004 && formik.errors.AFFN0004 ? (
                  <div className="error-msg">{formik.errors.AFFN0004}</div>
                ) : null}
              </div>
            </div>
                 {/*Image */}
                 <div className="grid grid-nogutter col-6 align-items-center mb-4">
              <div className="col-3">
                <label htmlFor="AFFN0069" className="cmsLabels">
                  Background Image{" "}
                  <span className="imageFormat">(png, jpeg, 1920W X 949H)</span>
                </label>
              </div>
              <div className="col-7">
                <div className="file-upload-section">
                  <PR.InputText
                    type="file"
                    name="AFFN0069"
                    className="file-upload-input"
                    accept="image/jpeg"
                    onChange={async (event) => {
                      const file = event.currentTarget.files[0];
                      setHowItWorksImage(file);
                      formik.handleChange(event);
                      uploadWidthHeightImage(
                        file,
                        "AFFN0069",
                        toast,
                        dispatch,
                        headers,
                        "affiliate-new"
                      );
                    }}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {howItWorksImage &&
                formik.touched.AFFN0069 &&
                formik.errors.AFFN0069 ? (
                  <div className="error-msg">{formik.errors.AFFN0069}</div>
                ) : null}
              </div>
              <div className="col-12">
                <div className="grid grid-nogutter">
                  <div className="col-12">
                    <div className="label-input-wrapper">
                      <div className="col-3 invisible-label">
                        {howItWorksImage && <p>Background Image :</p>}
                      </div>
                      <div>
                        <ImagePreview
                          formikError={formik.errors.AFFN0069}
                          defaultImage={assets?.AFFN0069}
                          newImage={howItWorksImage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
       </div>
            {/*Description */}
            <div className="grid grid-nogutter align-items-center mb-4">
              <div className="col-1 ml-3">
                <label htmlFor="AFFN0005" className="cmsLabels">
                  Description
                </label>
              </div>
              <div className="col-4">
                <div className="card file-upload-section">
                  <PR.InputText
                    id="AFFN0005"
                    name="AFFN0005"
                    aria-describedby="AFFN0005"
                    value={formik.values.AFFN0005}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.AFFN0005 && formik.errors.AFFN0005 ? (
                  <div className="error-msg">{formik.errors.AFFN0005}</div>
                ) : null}
              </div>
            </div>

            <div className="grid grid-nogutter">
              <div className="col-12">
                <div className="card">
                  <div className="grid">
                    <div className="col-2"></div>
                    <div className="col">
                      <div className="col-7 text-center">
                        <h4 className="mb-2 mt-0">Panel-1</h4>
                      </div>
                    </div>
                    <div className="col">
                      <div className="col-7 text-center">
                        <h4 className="mb-2 mt-0">Panel-2</h4>
                      </div>
                    </div>
                    <div className="col">
                      <div className="col-7 text-center">
                        <h4 className="mb-2 mt-0">Panel-3</h4>
                      </div>
                    </div>
                  </div>
                  <div className="label-input-wrapper align-items-center">
                    <div className="col-1">
                      <div className="col-12 mb-4">
                        <label htmlFor="panelHeader" className="cmsLabels">
                          Title
                        </label>
                      </div>
                      <div className="col-1 mb-4">
                        <label htmlFor="panelDescription" className="cmsLabels">
                          Description
                        </label>
                      </div>
                    </div>
                    {/* Panel-1 Section */}
                    <div className="col">
                      <div className="panel-card">
                        {/* Panel-1 Header */}
                        <div className="grid grid-nogutter align-items-center mb-3">
                          <div className="col-12">
                            <div className="file-upload-section">
                              <PR.InputText
                                id="AFFN0021"
                                name="AFFN0021"
                                aria-describedby="AFFN0021"
                                value={formik.values.AFFN0021}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.AFFN0021 &&
                            formik.errors.AFFN0021 ? (
                              <div className="error-msg">
                                {formik.errors.AFFN0021}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {/* Panel-1 Description */}
                        <div className="grid grid-nogutter align-items-center mb-3">
                          <div className="col-12">
                            <div className="file-upload-section">
                              <PR.InputText
                                id="AFFN0022"
                                name="AFFN0022"
                                aria-describedby="AFFN0022"
                                value={formik.values.AFFN0022}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.AFFN0022 &&
                            formik.errors.AFFN0022 ? (
                              <div className="error-msg">
                                {formik.errors.AFFN0022}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Panel-2 Section */}
                    <div className="col">
                      <div className="panel-card">
                        {/* Panel-2 Header */}
                        <div className="grid grid-nogutter align-items-center mb-3">
                          <div className="col-12">
                            <div className="file-upload-section">
                              <PR.InputText
                                id="AFFN0023"
                                name="AFFN0023"
                                aria-describedby="AFFN0023"
                                value={formik.values.AFFN0023}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.AFFN0023 &&
                            formik.errors.AFFN0023 ? (
                              <div className="error-msg">
                                {formik.errors.AFFN0023}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {/* Panel-2 Description */}
                        <div className="grid grid-nogutter align-items-center mb-3">
                          <div className="col-12">
                            <div className="file-upload-section">
                              <PR.InputText
                                id="AFFN0024"
                                name="AFFN0024"
                                aria-describedby="AFFN0024"
                                value={formik.values.AFFN0024}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.AFFN0024 &&
                            formik.errors.AFFN0024 ? (
                              <div className="error-msg">
                                {formik.errors.AFFN0024}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Panel-3  */}
                    <div className="col">
                      <div className="panel-card">
                        <div className="grid grid-nogutter align-items-center mb-3">
                          <div className="col-12">
                            <div className="file-upload-section">
                              <PR.InputText
                                id="AFFN0025"
                                name="AFFN0025"
                                aria-describedby="AFFN0025"
                                value={formik.values.AFFN0025}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.AFFN0025 &&
                            formik.errors.AFFN0025 ? (
                              <div className="error-msg">
                                {formik.errors.AFFN0025}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {/* Panel-3 Description */}
                        <div className="grid grid-nogutter align-items-center mb-3">
                          <div className="col-12">
                            <div className="file-upload-section">
                              <PR.InputText
                                id="AFFN0026"
                                name="AFFN0026"
                                aria-describedby="AFFN0026"
                                value={formik.values.AFFN0026}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.AFFN0026 &&
                            formik.errors.AFFN0026 ? (
                              <div className="error-msg">
                                {formik.errors.AFFN0026}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          <PR.Divider />
          {/* Who is it for Section */}
          <div className="grid grid-nogutter">
            <div className="col-12">
              <label htmlFor="panelsSection">
                <h3 className="mb-3 mt-1">Who is it for Section</h3>
              </label>
              {/*Header */}
              <div className="grid grid-nogutter align-items-center mb-4">
                <div className="col-2">
                  <label htmlFor="AFFN0006" className="cmsLabels">
                    Header
                  </label>
                </div>
                <div className="col-4">
                  <div className="card file-upload-section">
                    <PR.InputText
                      id="AFFN0006"
                      name="AFFN0006"
                      aria-describedby="AFFN0006"
                      value={formik.values.AFFN0006}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.AFFN0006 && formik.errors.AFFN0006 ? (
                    <div className="error-msg">{formik.errors.AFFN0006}</div>
                  ) : null}
                </div>
              </div>
              <div className="card">
                <div className="grid">
                  <div className="col-2"></div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-1</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-2</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-3</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-4</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-5</h4>
                    </div>
                  </div>
                </div>
                <div className="label-input-wrapper">
                  <div className="col-2"></div>
                  <div className="col">
                    <div className="grid grid-nogutter">
                      <div className="col-12">
                        <ImagePreview
                          formikError={formik.errors.AFFN0063}
                          defaultImage={assets?.AFFN0063}
                          newImage={benefitImage1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="grid grid-nogutter">
                      <div className="col-12">
                        <ImagePreview
                          formikError={formik.errors.AFFN0064}
                          defaultImage={assets?.AFFN0064}
                          newImage={benefitImage2}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="grid grid-nogutter">
                      <div className="col-12">
                        <ImagePreview
                          formikError={formik.errors.AFFN0065}
                          defaultImage={assets?.AFFN0065}
                          newImage={benefitImage3}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="grid grid-nogutter">
                      <div className="col-12">
                        <ImagePreview
                          formikError={formik.errors.AFFN0066}
                          defaultImage={assets?.AFFN0066}
                          newImage={benefitImage4}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="grid grid-nogutter">
                      <div className="col-12">
                        <ImagePreview
                          formikError={formik.errors.AFFN0067}
                          defaultImage={assets?.AFFN0067}
                          newImage={benefitImage5}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="label-input-wrapper align-items-center">
                  <div className="col-2">
                    <div className="col-12">
                      <label htmlFor="panelImage" className="cmsLabels">
                        Image{" "}
                      </label>
                    </div>
                    <div className="col-12 mt-4">
                      <label htmlFor="panelHeader" className="cmsLabels">
                        Title 1.1
                      </label>
                    </div>
                    <div className="col-12 mt-4">
                      <label htmlFor="panelHeader" className="cmsLabels">
                        Title 1.2
                      </label>
                    </div>
                  </div>
                  {/* Panel-1  */}
                  <div className="col">
                    <div className="panel-card">
                      {/* Panel-1 Image */}
                      <div className="grid grid-nogutter align-items-center">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="AFFN0063"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setBenefitImage1(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "AFFN0063",
                                  toast,
                                  dispatch,
                                  headers,
                                  "affiliate-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {benefitImage1 &&
                          formik.touched.AFFN0063 &&
                          formik.errors.AFFN0063 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0063}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <span className="imageFormat">
                        (png, jpeg, 53W X 70H)
                      </span>
                      {/* Panel-1 Header 1.1*/}
                      <div className="grid grid-nogutter align-items-center mt-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="AFFN0007"
                              name="AFFN0007"
                              aria-describedby="AFFN0007"
                              value={formik.values.AFFN0007}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.AFFN0007 && formik.errors.AFFN0007 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0007}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-1 Header 1.2*/}
                      <div className="grid grid-nogutter align-items-center mt-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="AFFN0061"
                              name="AFFN0061"
                              aria-describedby="AFFN0061"
                              value={formik.values.AFFN0061}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.AFFN0061 && formik.errors.AFFN0061 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0061}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Panel-2  */}
                  <div className="col">
                    <div className="panel-card">
                      {/* Panel-2 Image */}
                      <div className="grid grid-nogutter align-items-center">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="AFFN0064"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setBenefitImage2(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "AFFN0064",
                                  toast,
                                  dispatch,
                                  headers,
                                  "affiliate-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {benefitImage2 &&
                          formik.touched.AFFN0064 &&
                          formik.errors.AFFN0064 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0064}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <span className="imageFormat">
                        (png, jpeg, 75W X 75H)
                      </span>
                      {/* Panel-2 Header 1.1*/}
                      <div className="grid grid-nogutter align-items-center mt-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="AFFN0008"
                              name="AFFN0008"
                              aria-describedby="AFFN0008"
                              value={formik.values.AFFN0008}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.AFFN0008 && formik.errors.AFFN0008 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0008}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Panel-2 Header 1.2*/}
                      <div className="grid grid-nogutter align-items-center mt-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="AFFN0009"
                              name="AFFN0009"
                              aria-describedby="AFFN0009"
                              value={formik.values.AFFN0009}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.AFFN0009 && formik.errors.AFFN0009 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0009}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Panel-3  */}
                  <div className="col">
                    <div className="panel-card">
                      {/* Panel-3 Image */}
                      <div className="grid grid-nogutter align-items-center">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="AFFN0065"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setBenefitImage3(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "AFFN0065",
                                  toast,
                                  dispatch,
                                  headers,
                                  "affiliate-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {benefitImage3 &&
                          formik.touched.AFFN0065 &&
                          formik.errors.AFFN0065 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0065}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <span className="imageFormat">
                        (png, jpeg, 76W X 76H)
                      </span>
                      {/* Panel-2 Header 1.1*/}
                      <div className="grid grid-nogutter align-items-center mt-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="AFFN0010"
                              name="AFFN0010"
                              aria-describedby="AFFN0010"
                              value={formik.values.AFFN0010}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.AFFN0010 && formik.errors.AFFN0010 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0010}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Panel-2 Header 1.2*/}
                      <div className="grid grid-nogutter align-items-center mt-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="AFFN0011"
                              name="AFFN0011"
                              aria-describedby="AFFN0011"
                              value={formik.values.AFFN0011}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.AFFN0011 && formik.errors.AFFN0011 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0011}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="panel-card">
                      {/* Panel-4 Image */}
                      <div className="grid grid-nogutter align-items-center">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="AFFN0066"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setBenefitImage4(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "AFFN0066",
                                  toast,
                                  dispatch,
                                  headers,
                                  "affiliate-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {benefitImage4 &&
                          formik.touched.AFFN0066 &&
                          formik.errors.AFFN0066 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0066}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <span className="imageFormat">
                        (png, jpeg, 53W X 70H)
                      </span>
                      {/* Panel-4 Header 1.1*/}
                      <div className="grid grid-nogutter align-items-center mt-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="AFFN0012"
                              name="AFFN0012"
                              aria-describedby="AFFN0012"
                              value={formik.values.AFFN0012}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.AFFN0012 && formik.errors.AFFN0012 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0012}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Panel-4 Header 1.2*/}
                      <div className="grid grid-nogutter align-items-center mt-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="AFFN0013"
                              name="AFFN0013"
                              aria-describedby="AFFN0013"
                              value={formik.values.AFFN0013}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.AFFN0013 && formik.errors.AFFN0013 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0013}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Panel-5 Section */}
                  <div className="col">
                    <div className="panel-card">
                      {/* Panel-5 Image */}
                      <div className="grid grid-nogutter align-items-center">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="AFFN0067"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setBenefitImage5(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "AFFN0067",
                                  toast,
                                  dispatch,
                                  headers,
                                  "affiliate-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {benefitImage5 &&
                          formik.touched.AFFN0067 &&
                          formik.errors.AFFN0067 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0067}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <span className="imageFormat">
                        (png, jpeg, 75W X 75H)
                      </span>

                      {/* Panel-5 Header 1.1*/}
                      <div className="grid grid-nogutter align-items-center mt-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="AFFN0014"
                              name="AFFN0014"
                              aria-describedby="AFFN0014"
                              value={formik.values.AFFN0014}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.AFFN0014 && formik.errors.AFFN0014 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0014}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Panel-5 Header 1.2*/}
                      <div className="grid grid-nogutter align-items-center mt-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="AFFN0015"
                              name="AFFN0015"
                              aria-describedby="AFFN0015"
                              value={formik.values.AFFN0015}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.AFFN0015 && formik.errors.AFFN0015 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0015}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PR.Divider />

          {/* Affiliate Section */}
          <div className="file-upload">
            <div>
              <label htmlFor="partnerSection">
                <h3 className="mb-4 mt-1">Affiliate Section</h3>
              </label>
              <div className="grid grid-nogutter align-items-center mb-4">
                <div className="col-1">
                  <label htmlFor="AFFN0027" className="cmsLabels">
                    Header
                  </label>
                </div>
                <div className="col-4">
                  <div className="card file-upload-section">
                    <PR.InputText
                      id="AFFN0027"
                      name="AFFN0027"
                      aria-describedby="AFFN0027"
                      value={formik.values.AFFN0027}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.AFFN0027 && formik.errors.AFFN0027 ? (
                    <div className="error-msg">{formik.errors.AFFN0027}</div>
                  ) : null}
                </div>
              </div>
              <div className="card">
                <div className="grid">
                  <div className="col-6">
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-12 mb-2">
                        <label htmlFor="HOME0037">
                          <b>
                            Left Section Image{" "}
                            <span className="imageFormat">
                              (png, jpeg, 689W X 817H)
                            </span>
                          </b>
                        </label>
                      </div>
                      <div className="col-11 success-story-image-upload">
                        <div className="file-upload-section">
                          <PR.InputText
                            type="file"
                            name="AFFN0068"
                            className="file-upload-input"
                            accept="image/png, image/jpeg"
                            onChange={async (event) => {
                              const file = event.currentTarget.files[0];
                              setAffiliateImage(file);
                              formik.handleChange(event);
                              uploadWidthHeightImage(
                                file,
                                "AFFN0068",
                                toast,
                                dispatch,
                                headers,
                                "affiliate-new"
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {affiliateImage &&
                          formik.touched.AFFN0068 &&
                          formik.errors.AFFN0068 ? (
                            <div className="error-msg">
                              {formik.errors.AFFN0068}
                            </div>
                          ) : null}
                        </div>
                        <ImagePreview
                          formikError={formik.errors.AFFN0068}
                          defaultImage={assets.AFFN0068}
                          newImage={affiliateImage}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="grid">
                      <div className="col-6">
                        <div className="grid grid-nogutter align-items-center mb-4">
                          <div className="col-12 mb-2">
                            <label htmlFor="AFFN0017">
                              <b>Title</b>
                            </label>
                          </div>
                          <div className="col-12">
                            <div className="file-upload-section">
                              <PR.InputText
                                id="AFFN0017"
                                name="AFFN0017"
                                aria-describedby="AFFN0017"
                                value={formik.values.AFFN0017}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.AFFN0017 &&
                            formik.errors.AFFN0017 ? (
                              <div className="error-msg">
                                {formik.errors.AFFN0017}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="grid grid-nogutter align-items-center mb-4">
                          <div className="col-12 mb-2">
                            <label htmlFor="AFFN0018">
                              <b>Sub Title</b>
                            </label>
                          </div>
                          <div className="col-12">
                            <div className="file-upload-section">
                              <PR.InputText
                                id="AFFN0018"
                                name="AFFN0018"
                                aria-describedby="AFFN0018"
                                value={formik.values.AFFN0018}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.AFFN0018 &&
                            formik.errors.AFFN0018 ? (
                              <div className="error-msg">
                                {formik.errors.AFFN0018}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid"></div>
          </div>

          <PR.Divider />

          <div className="grid">
            <div className="col-10"></div>
            <div>
              <PR.Button
                label="Save"
                type="submit"
                className="saveBtn mt-2"
                disabled={disableSave}
              />
            </div>
          </div>
        </form>
      </div>
      <PR.Toast ref={toast} position="top-right" />
    </div>
  );
};
export default AffiliateShop;