import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import { useSelector, useDispatch } from 'react-redux';
import { getPromoCodesList, modifyPromoCode, getAffiliateUsersList, getPriceFilters, getCatalogsList, getCountriesList } from "../../services/api.jsx";
import { useFormik } from "formik";
import * as Yup from 'yup';
import * as utils from '../../utils';
import "../promo-code/PromoCode.scss";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { filterCountryName } from "../../utils/reuse.js";
import { globalConfig } from "../../GlobalConfig";
import { parseInt } from "lodash";

const PromoCode = () => {
    const dispatch = useDispatch();
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);
    const toast = useRef();
    const calendarRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState("")
    const [loading, setLoading] = useState(false);
    const [promoCodes, setPromoCodes] = useState([]);
    const sortFields = "status";
    const sortField = "createdTs";
    const sortOrder = 1;
    const [promoCodeError, setPromoCodeError] = useState("");
    const [filterValues, setFilterValues] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [selectedPromoCodeId, setSelectedPromoCodeId] = useState("")

    const [catalogLoading, setCatalogLoading] = useState(false)
    const [country, setCountry] = useState("");
    const [bundles, setBundles] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [createLoading, setCreateLoading] = useState(false)
    const [selectedBundles, setSelectedBundles] = useState([]);
    const [allCountries, setAllCountries] = useState([]);
    const [countries, setCountries] = useState([]);
    const [uniqueRegions, setUniqueRegions] = useState([]);
    const [provider, setProvider] = useState("");
    const [dataAmount, setDataAmount] = useState([]);
    const [dataAmountOptions, setDataAmountOptions] = useState([]);
    const [showInfo, setShowInfo] = useState(false);

    const showPaginator = bundles.length >= 10;

    const [filterOpen, setFilterOpen] = useState(false);
    const toggleFilter = () => setFilterOpen(!filterOpen);

    const percentage = [
        { name: '%', value: 'percent' },
        { name: '$', value: 'fixed' },
    ];
    const [affiliate, setAffiliate] = useState([])

    const affiliateEmail = ""
    const affiliateStatus = "active"
    const affiliatePhone = ""
    const affiliateName = ""

    const [discountType, setDiscountType] = useState(updateData?.discountAmount ? 'fixed' : 'percent');

    const initialFilters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'promoCode': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'percentage': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    }
    const [filters, setFilters] = useState(initialFilters)
    const statusDropdownVals = [
        { label: "ACTIVE", value: "ACTIVE" },
        { label: "EXPIRED", value: "EXPIRED" },
        { label: "PENDING", value: "PENDING" },
        { label: "USED", value: "USED" }
    ]
    const currentDate = new Date()
    const modifyFilterValuesRequest = values => {
        let status = values.status.length > 0 ? values.status.join(',') : '';
        let dates = values.date.length > 0 && values.date.filter(d => d !== null);
        let startTs = dates.length === 2 ? utils.formatDate(dates[0]) : dates.length === 1 ? utils.formatDate(dates[0]) : '';
        let endTs = dates.length === 2 ? utils.formatDate(dates[1]) : dates.length === 1 ? utils.formatDate(dates[0]) : '';
        return { startTs, endTs, status, type: 'fixed,percent' };
    }

    const searchFormik = useFormik({
        initialValues: {
            date: "",
            status: ""
        },
        onSubmit: values => setFilterValues(values)
    })

    const getRowClassName = (bundles, index) => {
        return index >= 12 ? 'highlight-row' : '';
    };

    const getPromoCodes = useCallback(async (request = { startTs: '', endTs: '', status: '', type: 'percent,fixed' }) => {

        setLoading(true);
        let getResponse = response => {
            if (response.result === "SUCCESS") {
                setLoading(false);
                const promoCodesList = response.data ? response.data : [];
                if (promoCodesList.length > 0) {
                    promoCodesList.map(val => {
                        val.formattedStartTs = moment(val.startTs).format("DD-MM-YYYY, HH:mm:ss");
                        val.formattedEndTs = moment(val.endTs).format("DD-MM-YYYY, HH:mm:ss");
                        val.formattedAllowedUse = val.maxNrOfUses
                        val.calendarStartTs = utils.formatCalendarDate(val.startTs);
                        val.calendarEndTs = utils.formatCalendarDate(val.endTs);
                        return val
                    })
                } else {
                    setLoading(false);
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'No records found' });
                }
                setPromoCodes(promoCodesList);
            } else {
                setLoading(false);
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        }
        const requestObj = filterValues ? modifyFilterValuesRequest(filterValues) : request
        getPromoCodesList(requestObj, headers, dispatch, getResponse)
    }, [dispatch, filterValues, headers])

    const initialValues = {
        id: updateData ? updateData.id : '',
        voucherType: updateData.discountBundle === "" || updateData.discountBundle === undefined ? "regular" : "bundleSpecific",
        affiliate: updateData ? updateData.afId : '',
        promoCode: updateData ? updateData.promoCode : '',
        discount: updateData
            ? (updateData.discountAmount ? updateData.discountAmount : updateData.discountPercentage)
            : null,
        startTs: updateData ? updateData.startTs : null,
        endTs: updateData ? updateData.endTs : null,
        maxNrOfUses: updateData?.maxNrOfUses !== -1 && updateData?.maxNrOfUses >= 1 ? updateData?.maxNrOfUses : null,
        unlimited: updateData?.maxNrOfUses === -1 || !updateData,
        oncePerUser: updateData.oncePerUser ? updateData.oncePerUser : false,
        country: updateData?.country ? updateData?.country : "No Country",
        region: updateData?.region ? updateData?.region : "No region",
        providerName: updateData?.providerName ? updateData?.providerName : "No provider",
    }

    const validationSchema = Yup.object({
        promoCode: Yup.string()
            .max(12, 'Promo code must be max 12 characters')
            .required('Promo code is required'),
        discount: Yup.number().nullable().required('Discount is required').max(100, 'Value must not exceed 100'),
        maxNrOfUses: Yup.number().when(`unlimited`, {
            is: (value) => !value,
            then: () => Yup.number().typeError('Usage Limit must be a number').required('Usage Limit is required').min(1, 'Usage limit must be at least 1'),
            otherwise: () => Yup.number().nullable(),
        }),
        startTs: Yup.string().trim()
            .required('Start Date is required')
            .nullable(),
        endTs: Yup.string().trim()
            .required('End Date is required')
            .nullable(),
    });

    const getAllAffiliateUsers = useCallback(async () => {
        const sortOrderVal = (sortOrder === 1) ? "asc" : "desc";
        const obj = {
            offset: 0,
            limit: 1000,
            sortField: sortField,
            sortOrder: sortOrderVal,
            name: affiliateName,
            email: affiliateEmail,
            phone: affiliatePhone,
            status: affiliateStatus,
        };
        getAffiliateUsersList(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const list = response.data;
                setAffiliate(list)
            }
        }, [sortOrder, sortField, headers, dispatch, affiliateEmail, affiliateName, affiliatePhone, affiliateStatus])
    }, [dispatch, headers, sortField, sortOrder])

    useEffect(() => {
        getAllAffiliateUsers()
    }, [getAllAffiliateUsers]);

    const handleSubmit = values => {
        setCreateLoading(true)
        if (formik.values.voucherType === "bundleSpecific" && (!selectedBundles || selectedBundles.length === 0) &&
            promoCodes.filter((code) => code?.promoCode === selectedPromoCodeId).flatMap((code) => code?.bundles || []).length === 0) {
            formik.setFieldError("voucherType", "Please select at least one bundle");
            setCreateLoading(false);
            return []
        }
        let currentDate = (d) => new Date(d)
        if (currentDate(values.startTs) > currentDate(values.endTs)) {
            setPromoCodeError("Start Date should be less than End Date")
            setCreateLoading(false);
            return null
        } else {
            const getResponse = response => {
                setCreateLoading(false)
                const summary = updateData ? 'updated' : 'added';
                if (response.result === "SUCCESS") {
                    setPromoCodeError("")
                    setLoading(false);
                    setVisible(false);
                    getPromoCodes();
                    formik.resetForm();
                    setFilterValues("");
                    setSearchValue("");
                    setFilters(initialFilters);
                    setSelectedBundles([])
                    searchFormik.setFieldValue("date", "")
                    searchFormik.setFieldValue("status", "")
                    toast.current.show({ severity: 'success', summary: `Voucher details ${summary} successfully` });
                } else {
                    setCreateLoading(false)
                    setLoading(false);
                    setPromoCodeError("")
                    const error = response.error;
                    setPromoCodeError(error.errorMsg ? error.errorMsg : error.summary)
                }
            }
            let addRequestObj = {
                promoCode: values.promoCode,
                discountBundle: selectedBundles.map((bundle) => bundle.name).join(","),
                startTs: moment.utc(values.startTs).format(),
                endTs: moment.utc(values.endTs).format(),
                maxNrOfUses: values.unlimited ? -1 : parseInt(values.maxNrOfUses),
                oncePerUser: values.oncePerUser,
                afId: formik.values.affiliate ? Number(formik.values.affiliate) : null
            }
            if (discountType === 'percent') {
                addRequestObj.discountPercentage = +values.discount;
                addRequestObj.discountType = 'percent';
            } else if (discountType === 'fixed') {
                addRequestObj.discountAmount = +values.discount;
                addRequestObj.discountType = 'fixed';
            }
            const updateRequestObj = {
                id: +values.id,
                promoCode: values.promoCode,
                discountBundle: promoCodes
                    .filter((code) => code.promoCode === selectedPromoCodeId)
                    .flatMap((code) => code.bundles)
                    .map((bundle) => bundle.name)
                    .join(","), 
                startTs: values.startTs,
                endTs: moment.utc(values.endTs).format(),
                maxNrOfUses: values.unlimited ? -1 : parseInt(values.maxNrOfUses),
                afId: formik.values.affiliate ? Number(formik.values.affiliate) : null,
                oncePerUser: values.oncePerUser,
            }

            if (discountType === 'percent') {
                updateRequestObj.discountPercentage = +values.discount;
                updateRequestObj.discountType = 'percent';
            } else if (discountType === 'fixed') {
                updateRequestObj.discountAmount = +values.discount;
                updateRequestObj.discountType = 'fixed';
            }
            const requestObj = updateData ? updateRequestObj : addRequestObj;
            modifyPromoCode(requestObj, headers, dispatch, getResponse);
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: handleSubmit,
        enableReinitialize: true
    })

    useEffect(() => {
        const selectedPromoBundles = promoCodes
            .filter((code) => code?.promoCode === selectedPromoCodeId)
            .flatMap((code) => code?.bundles || []);
    
        if (formik.values.voucherType === "bundleSpecific" && 
             selectedBundles.length > 0 ) {
            formik.setFieldError("voucherType", null);
        }

        if (formik.values.voucherType === "bundleSpecific" && 
            selectedPromoBundles.length > 0) {
            formik.setFieldError("voucherType", null);
        }
    }, [formik.values.voucherType, promoCodes, selectedPromoCodeId, selectedBundles, formik]);
    

    useEffect(() => {
        if (updateData) {
            if (updateData.discountAmount) {
                setDiscountType('fixed');
            } else if (updateData.discountPercentage) {
                setDiscountType('percent');
            }
        }
    }, [updateData]);

    const status = (rowData) => {
        let status = rowData.status === "ACTIVE"
            ? <span className="status completed">ACTIVE</span>
            : rowData.status === "PENDING"
                ? <span className="status pending">PENDING</span>
                : rowData.status === "EXPIRED"
                    ? <span className="status failed">EXPIRED</span>
                    : rowData.status === "USED"
                        ? <span className="status used">USED</span>
                        : "-"
        return status
    };

    const getAffiliatName = (rowData) => {
        const findAffiliate = affiliate.find(a => a.id === rowData.afId);
        return (findAffiliate?.name ? findAffiliate?.name : (rowData?.afId ? rowData?.afId : '-'))
    };

    const actions = (rowData) => {
        return (
            <>
                <PR.Button
                    icon="pi pi-copy"
                    className="action-btn edit"
                    title="Copy Promocode"
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                        // Copy the promo code to the clipboard
                        navigator.clipboard.writeText(rowData.promoCode)
                            .then(() => {
                                toast.current.show({
                                    severity: 'success',
                                    summary: 'Success',
                                    detail: 'Promo code copied to clipboard!',
                                    life: 3000 // Duration in milliseconds for how long the toast will show
                                });
                            })
                            .catch(err => {
                                toast.current.show({
                                    severity: 'error',
                                    summary: 'Error',
                                    detail: 'Failed to copy promo code.',
                                    life: 3000
                                });
                            });
                    }}
                />
                <PR.Button
                    icon="pi pi-pencil"
                    className="action-btn edit"
                    title="Edit"
                    onClick={() => {
                        getPromoCodes()
                        setSelectedPromoCodeId(rowData.promoCode);
                        setVisible(true)
                        setUpdateData(rowData)
                        setPromoCodeError("")
                        setShowInfo(false)
                        setBundles([])
                        setProvider("")
                        setCountry("")
                        setSelectedRegion(null)
                        setDataAmount([])
                    }}
                />
            </>
        );
    };

    useEffect(() => {
        getPromoCodes();
    }, [getPromoCodes]);

    const renderDiscount = (rowData) => {
        return rowData.discountType === 'percent' ? (rowData.discountPercentage + ' %') : utils.setPrice(rowData.discountAmount);
    }
    const formattedStartTs = (rowData) => rowData.formattedStartTs
    const formattedEndTs = (rowData) => rowData.formattedEndTs
    const formattedAllowedUse = (rowData) => rowData.formattedAllowedUse === -1 ? 'Unlimited' : rowData.formattedAllowedUse;

    const voucherType = (rowData) => (
        <div className="voucher-type">
          <span>{rowData.discountBundle === "" || rowData.discountBundle === undefined ? "Regular" : "Bundle Specific"}</span>
          <i
            className="pi pi-info-circle"
            onClick={() => {handleInfoClick(rowData)}}
            title="View Details"
            data-pr-position="top"
          />
        </div>
      );

      const handleInfoClick = (rowData) => {
        setShowInfo((prevState) => !prevState);
        setSelectedPromoCodeId(rowData.promoCode);
        setVisible(true)
        setUpdateData(rowData)
        setPromoCodeError("")
      };

    const gloabalSearchHandler = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setSearchValue(value);
    }


    const promoCodeHandler = e => {
        let cleaned = (formik.values.voucherCount > 1 && e.target.value > 15) ? e.target.value.replace(/[^0-9]/g, '').substring(0, 15) : e.target.value;
        formik.setFieldValue('promoCode', cleaned)
        setPromoCodeError("");
    }

    const discountHandler = (e) => {
        let discountValue = e.value;
        if (discountValue === null || /^[0-9\b]+$/.test(discountValue)) {
            formik.setFieldValue('discount', e.value);
        }
    };

    const affiliateChange = (e) => {
        const afID = e.value;  // e.value contains the selected affiliate's afId
        formik.setFieldValue('affiliate', afID);
    };

    const handleAffiliateOptions = (customers = []) => {
        return (Array.isArray(customers) ? customers : []).map(customer => ({
            label: customer.name,
            value: customer.id
        }));
    };

    const dateHandler = (e, fieldName) => {
        setPromoCodeError("");
        formik.setFieldValue(fieldName, moment(e.value).utc().format());
    };

    const maxUsesHandler = e => {
        let maxUses = e.value;
        if (!formik.values.unlimited) {
            if (maxUses !== null || /^[0-9\b]+$/.test(maxUses)) {
                formik.setFieldValue("maxNrOfUses", maxUses);
            }

        }
    }

    const handleUsageLimitChange = (e) => {
        const checked = e.target.checked;

        formik.setFieldValue("unlimited", checked);
    };

    const handleOncePerUser = (e) => {
        formik.setFieldValue('oncePerUser', e.target.checked)
    }

    const searchHandler = useCallback(() => {
        const updatedFilters = {
            country: country,
            region: selectedRegion,
            providerName: provider ? provider.map((item) => item?.name).join(",") : "",
            dataAmountForDisplay: dataAmount
                ? dataAmount.map((item) => item?.name).join(",")
                : "",
        };
    
        if (
            updatedFilters.country ||
            updatedFilters.region ||
            updatedFilters.providerName ||
            updatedFilters.dataAmountForDisplay
        ) {
            const defaultFilters = {
                sortingOrder: "asc",
                sortingField: "providerprice",
            };
    
            const queryParams = utils.serializeParams({
                ...updatedFilters,
                ...defaultFilters,
            });
    
            setCatalogLoading(true);
    
            getCatalogsList(queryParams, headers, dispatch, (response) => {
                setCatalogLoading(false);
                if (response.result === "SUCCESS") {
                    let catalogList = response.data || [];
                    catalogList = catalogList?.filter((c) => c.ourPrice !== -1);
                    if (Array.isArray(catalogList) && catalogList?.length > 0) {
                        setBundles(catalogList);
                    } else {
                        setBundles([]);
                        toast.current.show({
                            severity: "warn",
                            summary: "Warning",
                            detail: "No plans for the selected",
                        });
                    }
                } else {
                    setBundles([]);
                    const error = response?.error;
                    toast.current?.show({
                        severity: error.severity,
                        summary: "Error",
                        detail: error?.errorMsg || error?.summary,
                    });
                }
            });
        }
    }, [country, selectedRegion, provider, dataAmount, headers, dispatch]);
    
    useEffect(() => {
        const fetchDataAmountOptions = () => {
            getPriceFilters(headers, dispatch, (response) => {
                if (response.result === "SUCCESS") {
                    const fetchedOptions =
                        response.data?.dataAmountForDisplay?.length > 0
                            ? [...new Set(response.data?.dataAmountForDisplay)]
                            : [];
                    setDataAmountOptions(fetchedOptions);
                } else {
                    const error = response?.error;
                    toast.current?.show({
                        severity: error.severity,
                        summary: "Error",
                        detail: error?.errorMsg || error?.summary,
                    });
                }
            });
        };
    
        fetchDataAmountOptions();
    }, [headers, dispatch]);
    

    useEffect(() => {
        const handleCountriesResponse = (response) => {
            if (response.result === "SUCCESS") {
                const countriesData = response.data || [];
                if (countriesData.length > 0) {
                    const countriesList = countriesData.map(({ name, region, iso }) => ({
                        name: name.split("(")[0].trim(),
                        region,
                        iso,
                    }));
                    setAllCountries(countriesList);
                    setCountries(countriesList);
                    const uniqueRegionList = [
                        ...new Set(countriesList.map((item) => item.region)),
                    ].map((region) => ({ label: region, value: region }));
                    const unemptyRegionList = uniqueRegionList
                        ?.filter((region) => region.label && region.value)
                        .sort((a, b) => a.label.localeCompare(b.label)); // Removed empty object from the region list
                    setUniqueRegions(unemptyRegionList);
                }
            }
        };
        getCountriesList(headers, dispatch, handleCountriesResponse);
    }, [dispatch, headers]);

    const providerList = [
        { name: "eSIM-Go" },
        { name: "Airalo" },
        { name: "Cobira" },
    ];

    const handleRegionSelection = (region) => {
        setSelectedRegion(region);
        const countriesByRegion = region
            ? allCountries?.filter((c) => c.region === region)
            : allCountries;
        setCountries(countriesByRegion);
        const countryDisplay = countriesByRegion?.find((c) => c.iso === country)
            ? country
            : null;
        setCountry(region ? countryDisplay : "");
    };

    const handleCountrySelection = (e) => {
        const selectedCountry = e.value;
        setCountry(selectedCountry);
    };

    const handleProviderSelection = (e) => {
        setProvider(e.value);
    };

    const handleDataAmountSelection = (e) => {
        setDataAmount(e.value);
    };

    const formattedDataAmountOptions = dataAmountOptions.map(item => ({
        name: item,
    }));

    const voucherTypeChange = (e) => {
        formik.setFieldValue("voucherType", e.value)
    }

    const handleBundleSelection = (bundle, isChecked) => {
        setSelectedBundles((prevSelected) => {
            if (isChecked) {
                return [...prevSelected, bundle];
            } else {
                return prevSelected.filter((b) => b.name !== bundle.name);
            }
        });      
    }

    const handleUpdateBundleSelection = (bundle, isChecked) => {
        setPromoCodes((prevPromoCodes) => {
            const promoCodeEntry = prevPromoCodes.find((code) => code.promoCode === selectedPromoCodeId);
    
            if (isChecked) {
                if (promoCodeEntry) {
                    const updatedBundles = promoCodeEntry.bundles.some((b) => b.name === bundle.name)
                        ? promoCodeEntry.bundles
                        : [...promoCodeEntry.bundles, bundle];
    
                    return prevPromoCodes.map((code) =>
                        code.promoCode === selectedPromoCodeId
                            ? { ...code, bundles: updatedBundles }
                            : code
                    );
                } else {
                    return [
                        ...prevPromoCodes,
                        {
                            promoCode: selectedPromoCodeId,
                            bundles: [bundle],
                        },
                    ];
                }
            } else {
                if (promoCodeEntry) {
                    const updatedBundles = promoCodeEntry.bundles.filter((b) => b.name !== bundle.name);
    
                    return prevPromoCodes.map((code) =>
                        code.promoCode === selectedPromoCodeId
                            ? { ...code, bundles: updatedBundles }
                            : code
                    );
                }
                return prevPromoCodes;
            }
        });
    };

    const selectBox = (bundle) => {
        const isSelected = selectedBundles?.some((b) => b.name === bundle?.name);
        return (
            <PR.Checkbox
                checked={isSelected}
                onChange={(e) => handleBundleSelection(bundle, e.checked)}
            />
        );
    };

    const updateSelectBox = (bundle) => {
        const isSelected = promoCodes
        ?.find((code) => code.promoCode === selectedPromoCodeId)
        ?.bundles?.some((b) => b.name === bundle?.name);
        return (
            <PR.Checkbox
                checked={isSelected}
                onChange={(e) => handleUpdateBundleSelection(bundle, e.checked)}
            />
        );
    };

    const unSelectBox = (bundle) => {
        return (
            <PR.Button type="button" onClick={() => handleBundleSelection(bundle, false)} icon="pi pi-trash" className="bundle-close-button" tooltip="Delete bundle" tooltipOptions={{ position: 'bottom', className: 'bundle-delete-tooltip' }} />
        );
    };

    const updateUnSelectBox = (bundle) => {
        return (
            <PR.Button type="button" disabled={showInfo} onClick={() => handleUpdateBundleSelection(bundle, false)} icon="pi pi-trash" className="bundle-close-button" tooltip="Delete bundle" tooltipOptions={{ position: 'bottom', className: 'bundle-delete-tooltip' }} />
        );
    };    

    const onHideHandler = () => {
        setBundles([])
        setVisible(false);
        formik.resetForm();
        setSelectedBundles([]);
        setSelectedRegion('')
        setCountry('')
        setProvider('')
        setDataAmount([])
        setShowInfo(false)
    }

    const resetHandler = () => {
        setBundles([])
        setProvider('');
        setSelectedRegion('');
        setCountry('');
        setDataAmount([]);
        setSelectedBundles([]);
    };

    return (
        <>
            <div className="main">
                <div className="layout-sidebar">
                    <AdminHeader />
                </div>
                <div className="layout-content-wrapper">
                    <section className="admin-users-section promo-code-section">
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <div className="heading-sec">
                                    <div className="flex align-items-center justify-content-between mb-4">
                                        <h1>Discount Vouchers</h1>
                                        <PR.Button text className="filter-toggle-button" onClick={toggleFilter}>
                                            <i className={`pi ${filterOpen ? "pi-filter-slash" : "pi-filter-fill"}`}></i>
                                        </PR.Button>
                                    </div>
                                    <div className={`flex align-items-center justify-content-between filter-right ${filterOpen ? "mobile-filter-section" : ""}`}>
                                        <div className="flex mobile-flex">
                                            <form onSubmit={searchFormik.handleSubmit} className="flex align-items-center gap-2">
                                                <span className="p-input-icon-right search-field">
                                                    <i className="pi pi-search" />
                                                    <PR.InputText placeholder="Search" type="search" value={searchValue} onChange={gloabalSearchHandler} />
                                                </span>
                                                <PR.Calendar
                                                    ref={calendarRef}
                                                    value={searchFormik.values.date}
                                                    onChange={(e) => {
                                                        searchFormik.handleChange(e);
                                                        const selectedDates = e.value;
                                                        const validSelectedDates = selectedDates && selectedDates.filter(date => date !== null);
                                                        if (validSelectedDates?.length === 2) {
                                                            calendarRef.current.hide(); // Close the calendar popup
                                                        }
                                                    }}
                                                    selectionMode="range"
                                                    readOnlyInput
                                                    placeholder="Date range"
                                                    name="date"
                                                    dateFormat="dd-mm-yy"
                                                    showIcon
                                                    showButtonBar
                                                    numberOfMonths={2}
                                                    onClearButtonClick={() => searchFormik.setFieldValue("date", [])}
                                                />
                                                <PR.MultiSelect
                                                    placeholder="Status"
                                                    options={statusDropdownVals}
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    name="status"
                                                    value={searchFormik.values.status}
                                                    onChange={searchFormik.handleChange}
                                                    maxSelectedLabels={1}
                                                />
                                                <PR.Button type="submit" label="Search" className="searchBtn" />
                                                <PR.Button type="reset" label="Reset" className="resetBtn" onClick={() => {
                                                    searchFormik.handleReset();
                                                    setFilterValues("");
                                                    setSearchValue("");
                                                    setFilters(initialFilters);
                                                }} />
                                            </form>
                                        </div>
                                        <div className="right-section flex align-items-center gap-3">
                                            <PR.Button label="Create new promo" className="export-button" icon="pi pi-plus" iconPos="left" onClick={() => { setVisible(true); setUpdateData(""); setPromoCodeError(""); }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="users-data-table promocode-table card">
                            <PR.DataTable
                                loading={loading}
                                value={promoCodes}
                                paginator
                                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                paginatorDropdownAppendTo={"self"}
                                rows={50}
                                let-i="rowIndex"
                                filters={filters}
                                globalFilterFields={['promoCode', 'percentage']}
                                rowsPerPageOptions={[10, 20, 50]}
                                sortField={sortFields}
                                sortOrder={sortOrder}>
                                <PR.Column field="promoCode" header="Promo code" sortable className="promocode-column"></PR.Column>
                                <PR.Column field="voucherType" header="Type of voucher" body={voucherType} ></PR.Column>
                                <PR.Column field="discountPercentage" header="Discount" body={renderDiscount}></PR.Column>
                                <PR.Column field="startTs" header="Start Date" body={formattedStartTs} sortable></PR.Column>
                                <PR.Column field="endTs" header="End Date" body={formattedEndTs} sortable></PR.Column>
                                <PR.Column field="maxNrOfUses" header="Limit" body={formattedAllowedUse} sortable></PR.Column>
                                <PR.Column field="nrOfUses" header="Usage Count" sortable></PR.Column>
                                <PR.Column header="Status" field="status" sortable body={status} ></PR.Column>
                                <PR.Column header="Affiliate" field="affiliate" sortable body={getAffiliatName}></PR.Column>
                                <PR.Column body={actions} header="Actions"></PR.Column>
                            </PR.DataTable>
                        </div>
                    </section>
                    {/* Create new promo - Dialog */}
                    <PR.Dialog
                        header={showInfo? "View Promo Code" : (updateData ? "Update" : "Create New") + " Promo Code"}
                        visible={visible}
                        draggable={false}
                        resizable={false}
                        onHide={onHideHandler}
                        dismissableMask={true}
                        maskClassName='create-voucher-dialog-mask'
                        className="filter-dialog voucher-modal create-promo-dialog">
                        <div className="filter-content">
                            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                <div className="grid mt-2">
                                    <div className="col-12 md:col-4 pr-1 relative">
                                        <p className="mb-3">Voucher Name</p>
                                        <PR.InputText name="promoCode" value={formik.values.promoCode} onChange={promoCodeHandler} onBlur={formik.handleBlur} className="w-full" disabled={showInfo} />
                                        {formik.errors.promoCode && formik.touched.promoCode
                                            ? <div className='error-message'>{formik.errors.promoCode}</div>
                                            : ''
                                        }
                                    </div>
                                    <div className="col-12 md:col-4 pl-1">
                                        <p className="mb-3">Type of voucher</p>
                                        <PR.Dropdown
                                            name="voucherType"
                                            options={[
                                                { label: "Regular", value: "regular" },
                                                { label: "Bundle(s) specific", value: "bundleSpecific" }
                                            ]}
                                            value={formik.values.voucherType || "regular"}
                                            onChange={voucherTypeChange}
                                            placeholder="Select Type of Voucher"
                                            className="w-full"
                                            disabled={updateData}
                                        />
                                    </div>
                                    <div className="col-12 md:col-4 pl-1">
                                        <p className="mb-3">Affiliate ID <span>(Optional)</span></p>
                                        <PR.Dropdown name="affiliate" filter showClear={true} placeholder="Select Affiliate" value={formik.values.affiliate || ""} onChange={affiliateChange} options={handleAffiliateOptions(affiliate)} disabled={showInfo} className="w-full" />
                                    </div>
                                </div>

                                {/* Select Bundles */}

                                {formik.values.voucherType === "bundleSpecific" && !showInfo && (
                                    <>
                                        <div className="grid mt-2">
                                            <div className="col-12 pb-1">
                                                <p>Select a bundle</p>
                                            </div>
                                            <div className="col-12">
                                                <div className="flex gap-2 w-full align-items-center m-flex">
                                                    <PR.Dropdown
                                                        value={selectedRegion}
                                                        onChange={(e) => handleRegionSelection(e.value)}
                                                        options={uniqueRegions}
                                                        optionLabel="label"
                                                        placeholder="Select region"
                                                        filter
                                                        className="w-full"
                                                        disabled={catalogLoading || createLoading}
                                                        showClear={selectedRegion ? true : false}
                                                    />
                                                    <PR.Dropdown
                                                        value={country}
                                                        onChange={handleCountrySelection}
                                                        options={countries}
                                                        optionLabel="name"
                                                        optionValue="iso"
                                                        placeholder="Select country"
                                                        filter
                                                        resetFilterOnHide
                                                        className="w-full"
                                                        disabled={catalogLoading || createLoading}
                                                        showClear={country ? true : false}
                                                    />
                                                    <PR.MultiSelect
                                                        value={provider}
                                                        onChange={handleProviderSelection}
                                                        options={providerList}
                                                        optionLabel="name"
                                                        placeholder="Select provider"
                                                        className="w-full"
                                                        maxSelectedLabels={2}
                                                        disabled={catalogLoading || createLoading}
                                                    />
                                                    <PR.MultiSelect
                                                        value={dataAmount}
                                                        onChange={handleDataAmountSelection}
                                                        options={formattedDataAmountOptions}
                                                        optionLabel="name"
                                                        placeholder="Data Amount"
                                                        filter
                                                        resetFilterOnHide
                                                        className="w-full"
                                                        maxSelectedLabels={2}
                                                        panelClassName="data-amount-multiselect"
                                                        disabled={catalogLoading || createLoading}
                                                    />
                                                    <div className="btns-block">
                                                        <PR.Button
                                                            label="Search"
                                                            type="submit"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                searchHandler();
                                                            }}
                                                            className="search-button"
                                                            disabled={
                                                                (!provider.length && !selectedRegion && !country && !dataAmount) ||
                                                                catalogLoading || createLoading
                                                            }
                                                        />
                                                        <PR.Button
                                                            label="Reset"
                                                            type="button"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                resetHandler()
                                                            }}
                                                            className="search-button reset-btn"
                                                            disabled={
                                                                (!provider.length && !selectedRegion && !country && !dataAmount) ||
                                                                catalogLoading || createLoading
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid mt-2">
                                            <div className="col-12 pb-1">
                                            </div>
                                            <div className="col-12">
                                                <PR.DataTable
                                                    loading={catalogLoading || createLoading}
                                                    value={bundles}
                                                    rows={10}
                                                    paginator={showPaginator}
                                                    paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                    paginatorDropdownAppendTo={"self"}
                                                    paginatorClassName="select-bundle-pagination"
                                                    className="bundles-table custom-min-height select-bundle-datatable"
                                                    tableClassName={bundles.length > 10 ? 'highlight-tbody' : ''}
                                                    rowClassName={(rowData, index) => getRowClassName(rowData, index)} >
                                                    { !updateData ? <PR.Column body={selectBox} header="Select" style={{ width: '9%' }}></PR.Column>
                                                    : <PR.Column body={updateSelectBox} header="Select" style={{ width: '9%' }}></PR.Column>}
                                                    <PR.Column
                                                        field="description"
                                                        header="Name of the bundle"
                                                        style={{ width: '40%' }}
                                                        body={(rowData) => (rowData?.description ? `${filterCountryName(rowData?.description)}` : "-")}
                                                    ></PR.Column>
                                                    <PR.Column
                                                        field="dataAmountForDisplay"
                                                        header="Data"
                                                    ></PR.Column>
                                                    <PR.Column
                                                        field="duration"
                                                        header="Duration"
                                                        body={(rowData) => `${rowData?.duration} days`}
                                                    ></PR.Column>
                                                    <PR.Column
                                                        field="providerName"
                                                        header="Provider"
                                                    ></PR.Column>
                                                    <PR.Column
                                                        field="ourPrice"
                                                        header="Price"
                                                        style={{ width: '12%' }}
                                                        body={(rowData) => (
                                                            rowData?.ourPrice ? `${globalConfig.defaultCurrency} ${rowData?.ourPrice}` : '-'
                                                        )}
                                                    ></PR.Column>
                                                </PR.DataTable>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {/* Selected Bundles */}
                                {selectedBundles.length > 0 && !updateData &&
                                    <>
                                        <div className="col-12 p-0 mt-3">
                                            <p className="m-0">Selected Bundles ({selectedBundles.length})</p>
                                        </div>
                                        <div className="grid mt-1">
                                            <div className="col-12">
                                                <PR.DataTable
                                                    loading={catalogLoading || createLoading}
                                                    value={selectedBundles}
                                                    className="bundles-table custom-min-height select-bundle-datatable1"
                                                    tableClassName={selectedBundles.length > 10 ? 'highlight-tbody' : ''}
                                                    rowClassName={(rowData, index) => getRowClassName(rowData, index)} >
                                                    <PR.Column
                                                        field="description"
                                                        header="Name of the bundle"
                                                        style={{ width: '40%' }}
                                                        body={(rowData) => (rowData?.description ? `${filterCountryName(rowData?.description)}` : "-")}
                                                    ></PR.Column>
                                                    <PR.Column
                                                        field="dataAmountForDisplay"
                                                        header="Data"
                                                    ></PR.Column>
                                                    <PR.Column
                                                        field="duration"
                                                        header="Duration"
                                                        body={(rowData) => `${rowData?.duration} days`}
                                                    ></PR.Column>
                                                    <PR.Column
                                                        field="providerName"
                                                        header="Provider"
                                                    ></PR.Column>
                                                    <PR.Column
                                                        field="ourPrice"
                                                        header="Price"
                                                        style={{ width: '12%' }}
                                                        body={(rowData) => (
                                                            rowData?.ourPrice ? `${globalConfig.defaultCurrency} ${rowData?.ourPrice}` : '-'
                                                        )}
                                                    ></PR.Column>
                                                    <PR.Column body={unSelectBox} header="Action" style={{ width: '10%', textAlign: "center" }}></PR.Column>
                                                </PR.DataTable>
                                            </div>
                                        </div>
                                    </>
                                }

                                { /* updated SelectedBundles */}
                                {formik.values.voucherType === "bundleSpecific" && updateData && (
                                    <>
                                        <div className="col-12 p-0 mt-3">
                                            <p className="m-0">Selected Bundles ({promoCodes.filter((code) => code?.promoCode === selectedPromoCodeId).flatMap((code) => code?.bundles || []).length})</p>
                                        </div>
                                        {updateData && 
                                            <div className="grid mt-1">
                                            <div className="col-12">
                                                <PR.DataTable
                                                    value={promoCodes.filter((code) => code?.promoCode === selectedPromoCodeId).flatMap((code) => code?.bundles || [])}
                                                    className="bundles-table custom-min-height"
                                                >
                                                    {promoCodes.length === 0 && (
                                                        <PR.Column colSpan={1} style={{ textAlign: "center" }}>
                                                            No records found.
                                                        </PR.Column>
                                                    )}
                                                    <PR.Column
                                                        field="description"
                                                        header="Name of the bundle"
                                                        body={(rowData) => (rowData?.description ? `${filterCountryName(rowData?.description)}` : "-")}
                                                    ></PR.Column>
                                                    <PR.Column
                                                        field="dataAmountForDisplay"
                                                        header="Data"
                                                        body={(rowData) => (rowData?.dataAmountForDisplay ? rowData?.dataAmountForDisplay : "-")}
                                                    ></PR.Column>
                                                    <PR.Column
                                                        field="duration"
                                                        header="Duration"
                                                        body={(rowData) => (rowData?.duration ? `${rowData?.duration} days` : "-")}
                                                    ></PR.Column>
                                                    <PR.Column
                                                        field="providerName"
                                                        header="Provider"
                                                        body={(rowData) => (rowData?.providerName ? rowData?.providerName : "-")}
                                                    ></PR.Column>
                                                    <PR.Column
                                                        field="ourPrice"
                                                        header="Price"
                                                        style={{ width: '12%' }}
                                                        body={(rowData) => (
                                                            rowData?.ourPrice ? `${globalConfig.defaultCurrency} ${rowData?.ourPrice}` : '-'
                                                        )}
                                                    ></PR.Column>
                                                    <PR.Column body={updateUnSelectBox} header="Action" style={{ width: '10%', textAlign: "center" }}></PR.Column>
                                                </PR.DataTable>
                                                </div>
                                            </div>
                                        }
                                    </>
                                )}
                                {formik.errors.voucherType && (
                                    <div className='error-message relative'>{formik.errors.voucherType}</div>
                                )}

                                <div className="grid mt-2">
                                    <div className="col-12 pb-1">
                                        <p className="mb-0">Value & Usage</p>
                                    </div>
                                    <div className="col-12">
                                        <div className='grid align-items-center'>
                                            <div className="col-12 md:col-4">
                                                <div className="relative icon-input mt-1">
                                                    <PR.InputNumber name="discount" value={formik.values.discount || null} onChange={discountHandler} onBlur={formik.handleBlur} className="w-full" placeholder="Discount value" disabled={showInfo} />
                                                    <PR.Dropdown value={discountType} onChange={(e) => setDiscountType(e.value)} options={percentage} optionLabel="name" placeholder="%" className="discount-dropdown" disabled={updateData && showInfo} />
                                                    {formik.errors.discount && formik.touched.discount
                                                        ? <div className='error-message'>{formik.errors.discount}</div>
                                                        : ''
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-5">
                                                <div className="relative icon-input mt-0">
                                                    <div className="flex align-items-center">
                                                        <label className="flex align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={formik.values.unlimited}
                                                                onChange={handleUsageLimitChange}
                                                                className="usage-limit-checkbox"
                                                                disabled={showInfo}
                                                            />
                                                            <span>Unlimited</span>
                                                        </label>&nbsp;
                                                        <i className="pi pi-info-circle unlimited-tooltip relative-tooltip"></i>
                                                        <PR.Tooltip target=".unlimited-tooltip">
                                                            <span>Set number of time the promo code can be used to unlimited </span>
                                                        </PR.Tooltip>

                                                        <div className="relative icon-input mt-1 unlimited-input">
                                                            <PR.InputNumber
                                                                disabled={formik.values.unlimited ? true : false}
                                                                name="maxNrOfUses"
                                                                value={formik.values.maxNrOfUses || null}
                                                                onChange={maxUsesHandler}
                                                                onBlur={formik.handleBlur}
                                                                min={1}
                                                                className="w-full"
                                                                placeholder="Usage limit"
                                                            />
                                                            <i className="pi pi-info-circle usage-limit-tooltip"></i>

                                                            <PR.Tooltip target=".usage-limit-tooltip">
                                                                <span>Number of time the promo code can be used.</span>
                                                            </PR.Tooltip>
                                                            {formik.errors.maxNrOfUses && formik.touched.maxNrOfUses
                                                                ? <div className='error-message'>{formik.errors.maxNrOfUses}</div>
                                                                : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 md:col-3">
                                                <div className="flex align-items-center icon-input">
                                                    <label className="flex align-items-center">
                                                        <input
                                                            type="checkbox"
                                                            checked={formik.values.oncePerUser}
                                                            onChange={handleOncePerUser}
                                                            className="usage-limit-checkbox"
                                                            disabled={showInfo}
                                                        />
                                                        <span>One time/user</span>
                                                    </label>&nbsp;
                                                    <i className="pi pi-info-circle onetime-tooltip relative-tooltip"></i>
                                                    <PR.Tooltip target=".onetime-tooltip">
                                                        <span>If set, each individual user can use the promo code only once.</span>
                                                    </PR.Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid mt-2">
                                    <div className="col-12 pb-1">
                                        <p className="mb-0">Duration</p>
                                    </div>
                                    <div className="col-12">
                                        <div className='grid align-items-center'>
                                            <div className="col-6">
                                                <PR.Calendar
                                                    name="startTs"
                                                    value={formik.values.startTs ? moment(formik.values.startTs).toDate() : null}
                                                    onChange={(e) => dateHandler(e, 'startTs')}
                                                    onBlur={formik.handleBlur}
                                                    dateFormat="dd/mm/yy"
                                                    showIcon
                                                    minDate={currentDate}
                                                    placeholder="Start date"
                                                    disabled={showInfo}
                                                />
                                                {formik?.errors?.startTs && formik?.touched?.startTs && formik.values.startTs === null && (
                                                    <div className='error-message relative'>{formik?.errors?.startTs}</div>
                                                )}
                                            </div>
                                            <div className="col-6">
                                                <PR.Calendar
                                                    name="endTs"
                                                    value={formik.values.endTs ? moment(formik.values.endTs).toDate() : null}
                                                    onChange={(e) => dateHandler(e, 'endTs')}
                                                    onBlur={formik.handleBlur}
                                                    showIcon
                                                    minDate={currentDate}
                                                    dateFormat="dd/mm/yy"
                                                    placeholder="End date"
                                                    disabled={showInfo}
                                                />
                                                {formik.errors?.endTs && formik.touched?.endTs && formik.values?.endTs === null && (
                                                    <div className='error-message relative'>{formik.errors.endTs}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {promoCodeError && <div className='error-message relative'>{promoCodeError}</div>}
                                <div className="buttons-sections flex align-items-center mt-3">
                                {!showInfo &&<PR.Button label={updateData ? "UPDATE" : "Create"} type="submit" className="confirm-button promo-create-button"/>}
                                </div>
                            </form>
                        </div>
                    </PR.Dialog>
                    <PR.Toast ref={toast} position='top-right' />
                    <AdminFooter />
                </div>
            </div>
        </>
    );

};

export default PromoCode;