import React, { useState, useMemo } from 'react';
import * as PR from "../../../prime-modules/index";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { uploadAssetsApi } from "../../../services/api"
import { useDispatch, useSelector } from 'react-redux';
import "./Partner.scss";
import { trimFormData, validateTextField } from '../../../utils';
import { getAllAssets } from '../../../genericFunctions/getAssets';
import ImagePreview from '../generic/imagePreview';
import { uploadWidthHeightImage } from '../../../utils/reuse';

const About = ({assets, disableSave}) => {
    const dispatch = useDispatch();
    const toast = React.useRef(null)
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);

    const [enhancedCustomerImage, setEnhancedCustomerImage] = useState(null);
    const [streamlinedIntegrationImage, setStreamlinedIntegrationImage] = useState(null);
    const [winPartnershipImage, setWinPartnershipImage] = useState(null);
    const [formImage, setFormImage] = useState(null);

    const validationSchema = Yup.object().shape({
        PART0001: validateTextField(10, 30, 'Header'),
        PART0002: validateTextField(50, 100, 'Description'),
        PART0004: validateTextField(15, 40, 'Enhanced Customer Header'),
        PART0005: validateTextField(60, 260, 'Enhanced Customer Description'),
        PART0006: validateTextField(10, 30, 'Streamlined Integration Header'),
        PART0007: validateTextField(100, 260, 'Streamlined Integration Description'),
        PART0010: validateTextField(10, 60, 'Win Partnership Header'),
        PART0011: validateTextField(80, 300, 'Win Partnership Description'),
        PART0012: validateTextField(20, 60, 'Header'),
        PART0013: validateTextField(70, 170, 'Description'),
        PART0014: validateTextField(20, 60, 'Form Header'),
        PART0015: validateTextField(20, 60, 'Description'),
        PART0016: Yup.string().trim().email("Invalid Email").required("Email required"),
    });

    const handleSubmit = async (data) => {
        const formData = new FormData();
        const trimmedFormData = trimFormData(data);
        formData.append('screen', 'partner');
        for (const field in trimmedFormData) {
            if (trimmedFormData.hasOwnProperty(field)) {
                const value = trimmedFormData[field];
                if (value && typeof value === 'string' && !(value.startsWith('iVBORw0KGgoAAAANSUhEUg') || value.startsWith('/9j/4AAQSkZJRgA'))) {
                    formData.append(field, value);
                }
            }        
        }
        const getCMSResponse = (response) => {
            if (response.result === 'SUCCESS') {
                if(response.data === 'success'){
                  toast.current.show({ severity: 'success', summary: 'Success', detail: ' Updated successfully' });
                }
                getAllAssets(toast, dispatch, headers);
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        };
        uploadAssetsApi(formData, headers, dispatch, getCMSResponse);
    }



    const formik = useFormik({
        initialValues: assets,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true, 
    })

    return (
        <div className='contact'>
            <div className="card">
                {/* <h2>About Us Settings</h2> */}
                <form autoComplete="off" onSubmit={formik.handleSubmit} >
                    <div className="imageFormat mb-4 ">
                        <strong>(Image Size Should not exceed 1MB)</strong>
                    </div>
                    <div className="grid grid-nogutter align-items-center mb-4 mt-4">
                        <div className='col-2'>
                            <label htmlFor="PART0001" className="cmsLabels">Top Header</label>
                        </div>
                        <div className="col-5">
                            <div className="file-upload-section">
                                <PR.InputText
                                    id="PART0001"
                                    name="PART0001"
                                    aria-describedby="PART0001"
                                    value={formik.values.PART0001}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.PART0001 && formik.errors.PART0001 ? (
                                <div className='error-msg'>{formik.errors.PART0001}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-2'>
                            <label htmlFor="PART0002" className="cmsLabels">Top Header Description</label>
                        </div>
                        <div className="col-5">
                            <div className="file-upload-section">
                                <PR.InputText
                                    id="PART0002"
                                    name="PART0002"
                                    aria-describedby="PART0002"
                                    value={formik.values.PART0002}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.PART0002 && formik.errors.PART0002 ? (
                                <div className='error-msg'>{formik.errors.PART0002}</div>
                            ) : null}
                        </div>
                    </div>
                    <PR.Divider />
                    <div className="card">
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <label htmlFor="UnlockWorld" className="mb-2"><h3 className="mt-1 mb-2">Enhanced Customer Experience Section</h3></label>
                            </div>
                        </div>
                        <div className="grid grid-nogutter mt-2">
                            <div className="col-6">
                                <div className="grid grid-nogutter align-items-center">
                                    <div className='col-3'>
                                        <label htmlFor="PART0003" className="cmsLabels">Image <span className='imageFormat'>(png, jpeg, 514W X 340H)</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                type="file"
                                                name="PART0003"
                                                className="file-upload-input"
                                                accept="image/png, image/jpeg"
                                                onChange={async(event) => {
                                                    const file = event.currentTarget.files[0];
                                                    setEnhancedCustomerImage(file);
                                                    formik.handleChange(event);
                                                    uploadWidthHeightImage(file, "PART0003", toast, dispatch, headers, 'partner')
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                            {enhancedCustomerImage && formik.touched.PART0003 && formik.errors.PART0003 ? (
                                                <div className='error-msg'>{formik.errors.PART0003}</div>
                                            ) : null}

                                            <ImagePreview formikError={formik.errors.PART0003} defaultImage={assets?.PART0003} newImage={enhancedCustomerImage}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="PART0004" className="cmsLabels">Header</label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="PART0004"
                                                name="PART0004"
                                                aria-describedby="PART0004"
                                                value={formik.values.PART0004}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.PART0004 && formik.errors.PART0004? (
                                            <div className='error-msg'>{formik.errors.PART0004}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-3">
                                <div className='col-3'>
                                        <label htmlFor="PART0005" className="cmsLabels">Description</label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="PART0005"
                                                name="PART0005"
                                                aria-describedby="PART0005"
                                                value={formik.values.PART0005}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.PART0005 && formik.errors.PART0005 ? (
                                            <div className='error-msg'>{formik.errors.PART0005}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PR.Divider />
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <label htmlFor="instantActivationSection" className="mb-2"><h3 className="mt-1 mb-2">Streamlined Integration Section</h3></label>
                            </div>
                        </div>
                        <div className="grid grid-nogutter mt-3">
                            <div className='col-6'>
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="PART0006" className="cmsLabels">Header</label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="PART0006"
                                                name="PART0006"
                                                aria-describedby="PART0006"
                                                value={formik.values.PART0006}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.PART0006 && formik.errors.PART0006 ? (
                                            <div className='error-msg'>{formik.errors.PART0006}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-3">
                                    <div className='col-3'>
                                        <label htmlFor="PART0007" className="cmsLabels">Description</label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="PART0007"
                                                name="PART0007"
                                                aria-describedby="PART0007"
                                                value={formik.values.PART0007}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.PART0007 && formik.errors.PART0007 ? (
                                            <div className='error-msg'>{formik.errors.PART0007}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="PART0008" className="cmsLabels">Image <span className='imageFormat'>(png, jpeg, 514W X 340H)</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                type="file"
                                                name="PART0008"
                                                className="file-upload-input"
                                                accept="image/png, image/jpeg"
                                                onChange={async(event) => {
                                                    const file = event.currentTarget.files[0];
                                                    setStreamlinedIntegrationImage(file);
                                                    formik.handleChange(event);
                                                    uploadWidthHeightImage(file, "PART0008", toast, dispatch, headers, 'partner')
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                            {streamlinedIntegrationImage && formik.touched.PART0008 && formik.errors.PART0008 ? (
                                                <div className='error-msg'>{formik.errors.PART0008}</div>
                                            ) : null}
                                            <ImagePreview formikError={formik.errors.PART0008} defaultImage={assets?.PART0008} newImage={streamlinedIntegrationImage}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <PR.Divider />
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <label htmlFor="UnlockWorld" className="mb-2"><h3 className="mt-1 mb-2">Win-Win Partnership Section</h3></label>
                            </div>
                        </div>
                        <div className="label-input-wrapper grid grid-nogutter mt-3">
                            <div className="col-6">
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="PART0009" className="cmsLabels">Image  <span className='imageFormat'>(png, jpeg, 514W X 341H)</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                type="file"
                                                name="PART0009"
                                                className="file-upload-input"
                                                accept="image/png, image/jpeg"
                                                onChange={async(event) => {
                                                    const file = event.currentTarget.files[0];
                                                    setWinPartnershipImage(file);
                                                    formik.handleChange(event);
                                                    uploadWidthHeightImage(file, "PART0009", toast, dispatch, headers, 'partner')
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                            {winPartnershipImage && formik.touched.PART0009 && formik.errors.PART0009 ? (
                                                <div className='error-msg'>{formik.errors.PART0009}</div>
                                            ) : null}

                                            <ImagePreview formikError={formik.errors.PART0009} defaultImage={assets?.PART0009} newImage={winPartnershipImage}/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='col-6'>
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="PART0010" className="cmsLabels"><span>Header</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="PART0010"
                                                name="PART0010"
                                                aria-describedby="PART0010"
                                                value={formik.values.PART0010}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.PART0010 && formik.errors.PART0010 ? (
                                            <div className='error-msg'>{formik.errors.PART0010}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-3">
                                    <div className='col-3'>
                                        <label htmlFor="PART0011" className="cmsLabels"><span>Description</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="PART0011"
                                                name="PART0011"
                                                aria-describedby="PART0011"
                                                value={formik.values.PART0011}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.PART0011 && formik.errors.PART0011 ? (
                                            <div className='error-msg'>{formik.errors.PART0011}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PR.Divider />
                                                
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <label htmlFor="panelIcons" className="mb-0"><h3 className="mt-1 mb-2">Form Section</h3></label>
                            </div>
                        </div>
                        <div className="grid grid-nogutter mt-3">
                            <div className='col-6'>
                            <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="PART0012" className="cmsLabels"><span>Top Header</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="PART0012"
                                                name="PART0012"
                                                aria-describedby="PART0012"
                                                value={formik.values.PART0012}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.PART0012 && formik.errors.PART0012 ? (
                                            <div className='error-msg'>{formik.errors.PART0012}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="PART0013" className="cmsLabels"><span>Top Description</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputTextarea
                                                autoResize
                                                id="PART0013"
                                                name="PART0013"
                                                aria-describedby="PART0013"
                                                value={formik.values.PART0013}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.PART0013 && formik.errors.PART0013 ? (
                                            <div className='error-msg'>{formik.errors.PART0013}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="PART0014" className="cmsLabels"><span>Header</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="PART0014"
                                                name="PART0014"
                                                aria-describedby="PART0014"
                                                value={formik.values.PART0014}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.PART0014 && formik.errors.PART0014 ? (
                                            <div className='error-msg'>{formik.errors.PART0014}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="PART0015" className="cmsLabels"><span>Description</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="PART0015"
                                                name="PART0015"
                                                aria-describedby="PART0015"
                                                value={formik.values.PART0015}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.PART0015 && formik.errors.PART0015? (
                                            <div className='error-msg'>{formik.errors.PART0015}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-3">
                                    <div className='col-3'>
                                        <label htmlFor="PART0016" className="cmsLabels"><span>Email</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="PART0016"
                                                name="PART0016"
                                                aria-describedby="PART0016"
                                                value={formik.values.PART0016}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.PART0016 && formik.errors.PART0016 ? (
                                            <div className='error-msg'>{formik.errors.PART0016}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="grid grid-nogutter align-items-center">
                                    <div className='col-3'>
                                        <label htmlFor="PART0017" className="cmsLabels"><span>Image</span> <span className='imageFormat'>(png, jpeg, 1040W X 941H)</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                type="file"
                                                name="PART0017"
                                                className="file-upload-input"
                                                accept="image/png, image/jpeg"
                                                onChange={async(event) => {
                                                    const file = event.currentTarget.files[0];
                                                    setFormImage(file);
                                                    formik.handleChange(event);
                                                    uploadWidthHeightImage(file, "PART0017", toast, dispatch, headers, 'partner')
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formImage && formik.touched.PART0017 && formik.errors.PART0017 ? (
                                                <div className='error-msg'>{formik.errors.PART0017}</div>
                                            ) : null}

                                            <ImagePreview formikError={formik.errors.PART0017} defaultImage={assets?.PART0017} newImage={formImage}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <PR.Divider />
                    </div>

                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <PR.Button label="Save" type='submit' className="saveBtn" disabled={disableSave}/>
                        </div>
                    </div>

                </form>
            </div>
            <PR.Toast ref={toast} position="top-right" />
        </div>
    );
};
export default About;