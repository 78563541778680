import React, { memo } from "react";
import * as PR from "../../prime-modules/index";

const TabsBlock = (props) => {
  const tabList = props ? props.selectedTabs : [
    {
        "id": "revenue",
        "name": "Revenue",
        "previousIntervalVal": "0",
        "currentIntervalVal": "0",
        "percentageDiff": "0"
    },
    {
        "id": "bundlesSold",
        "name": "Bundles Sold",
        "previousIntervalVal": "0",
        "currentIntervalVal": "0",
        "percentageDiff": "0"
    }
];
  const showCurrentMonth = props ? props.currentMonthButton : false

  const renderPercentageDiff = (percentageDiff) => {
    if (percentageDiff === "+∞") {
      return "+∞";
    } else if (percentageDiff === "-∞") {
      return "-∞";
    } else if (percentageDiff != null) {
      return `${Math.abs(percentageDiff)}%`;
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="grid">
        {tabList.length > 0 ? (
          tabList.map((tab, index) => (
            <div className="col-12 md:col-6 lg:col-3 sm:col-6 col-custom-width" key={index}>
              <div className="p-3 border-1 border-50 border-round tabs-block-bg">
                <div className="flex justify-content-between align-items-center mb-2 tabs-block-mobile">
                  <span>{tab.name}</span>
                </div>
                <div className="flex justify-content-between align-items-center mt-3 price-block-mobile">
                  <div>
                    <div className="tab-number flex align-items-center">
                      <div className="mr-3">
                        {tab.id === "bundlesSold"
                          ? showCurrentMonth
                            ? tab.currentIntervalVal
                            : tab.previousIntervalVal
                          : showCurrentMonth
                          ? tab.currentIntervalVal
                          : tab.previousIntervalVal}
                      </div>
                      {tab.percentageDiff && (
                        <PR.Tooltip target=".percentage" position="bottom">
                          Compared to Last Month
                        </PR.Tooltip>
                      )}

                        {tab.percentageDiff && (
                            <span
                              className={
                                "percentage " +
                                (tab.percentageDiff === "+∞" || tab.percentageDiff > 0
                                  ? "up"
                                  : tab.percentageDiff === "-∞" || tab.percentageDiff < 0
                                  ? "down"
                                  : "normal")
                              }
                            >
                              {(tab.percentageDiff === "+∞" || tab.percentageDiff > 0) && (
                                <i className="pi pi-arrow-up-right" />
                              )}
                              {(tab.percentageDiff === "-∞" || tab.percentageDiff < 0) && (
                                <i className="pi pi-arrow-down-left" />
                              )}
                              {renderPercentageDiff(tab.percentageDiff)}
                            </span>
                          )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>No Tabs were selected to display</div>
        )}
      </div>
    </>
  );
};

export default memo(TabsBlock);
