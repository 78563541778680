import React, { useState, useMemo } from "react";
import * as PR from "../../../prime-modules/index";
import "./Shop.scss";
import { useFormik } from "formik";
import { uploadAssetsApi } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { trimFormData, validateTextField } from "../../../utils";
import { getAllAssets } from "../../../genericFunctions/getAssets";
import ImagePreview from "../generic/imagePreview";
import { uploadWidthHeightImage } from "../../../utils/reuse";
// import { isValid } from 'i18n-iso-countries';

const Shop = ({ assets, disableSave }) => {
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const adminData = useSelector((state) => state.adminAuth.adminSessionData);
  const headers = useMemo(() => {
    return { sessionid: adminData.sessionId };
  }, [adminData.sessionId]);

  const [appStoreImage, setAppStoreImage] = useState(null);
  const [playStoreImage, setPlayStoreImage] = useState(null);
  const [mobileImage, setMobileImage] = useState(null);
  const [features1, setFeatures1] = useState(null);
  const [features2, setFeatures2] = useState(null);
  const [features3, setFeatures3] = useState(null);
  const [activation1, setActivation1] = useState(null);
  const [activation2, setActivation2] = useState(null);
  const [activation3, setActivation3] = useState(null);
  const [globalCoverage, setGlobalCoverage] = useState(null);
  const [FAQRightImage, setFAQRightImage] = useState(null);
  const [getInTouchImage, setgetInTouchImage] = useState(null);
  const [partnerLeftImage, setPartnerLeftImage] = useState(null);

  // const validationSchema = Yup.object().shape({
  //   HOMEN0002: validateTextField(20, 100, "Top Header"),
  //   HOMEN0003: validateTextField(20, 250, "Top Header Description"),
  //   HOMEN0053: validateTextField(10, 30, "Instant Activation"),
  //   HOMEN0054: validateTextField(20, 200, "Instant Description"),
  //   HOMEN0055: validateTextField(5, 30, "Global Coverage"),
  //   HOMEN0056: validateTextField(20, 200, "Global Description"),
  //   HOMEN0057: validateTextField(5, 30, "Attractive Rates"),
  //   HOMEN0058: validateTextField(20, 200, "Attractive Description"),
  //   HOMEN0036: validateTextField(20, 50, "Activation"),
  //   HOMEN0019: validateTextField(20, 100, "Download oru app"),
  //   HOMEN0022: validateTextField(20, 100, "Choose and purchase your ideal data plan"),
  //   HOMEN0025: validateTextField(20, 100, "Activate your eSIM and stay connected!"),
  //   HOMEN0015: validateTextField(20, 50, "Global Coverage"),
  //   HOMEN0016: validateTextField(20, 200, "Global Description"),
  //   HOMEN0017: validateTextField(20, 200, "Global Button"),
  //   HOMEN0059: validateTextField(20, 200, "FAQ"),
  //   HOMEN0060: validateTextField(20, 200, "FAQ Button"),
  //   HOMEN0049: validateTextField(20, 200, "Get in touch"),
  //   HOMEN0061: validateTextField(20, 200, "Get in touch Description"),
  //   HOMEN0062: validateTextField(20, 200, "Get in touch Button"),
  //   HOMEN0063: validateTextField(20, 200, "Title"),
  //   HOMEN0064: validateTextField(20, 200, "Sub Title"),
  //   HOMEN0065: validateTextField(20, 200, "Sales"),
  //   HOMEN0066: validateTextField(20, 200, "Sales Description"),
  //   HOMEN0067: validateTextField(20, 200, "Sales mailId"),
  //   HOMEN0068: validateTextField(20, 100, "Contact Us"),
  //   HOMEN0069: validateTextField(20, 200, "Contact Us Description"),
  //   HOMEN0070: validateTextField(20, 200, "Contact Us Number"),
  //   HOMEN0071: validateTextField(20, 200, "Support"),
  //   HOMEN0072: validateTextField(20, 200, "Support Description"),
  //   HOMEN0073: validateTextField(20, 200, "Support mailId"),
  //   HOMEN0038: validateTextField(20, 50, "APP Header"),
  //   HOMEN0039: validateTextField(20, 200, "APP Description"),
  //   HOMEN0040: validateTextField(20, 50, "Play Store Link"),
  //   HOMEN0041: validateTextField(5, 30, "App Store Link"),
  //   HOMEN0052: validateTextField(20, 200, "Benefit Description"),
  // });

  const handleSubmit = async (data) => {
    const formData = new FormData();
    const trimmedFormData = trimFormData(data);
    formData.append("screen", "home-new");
    for (const field in trimmedFormData) {
      if (trimmedFormData.hasOwnProperty(field)) {
        const value = trimmedFormData[field];
        if (
          value &&
          typeof value === "string" &&
          !(
            value.startsWith("iVBORw0KGgoAAAANSUhEUg") ||
            value.startsWith("/9j/4AAQSkZJRgA")
          )
        ) {
          formData.append(field, value);
        }
      }
    }
    const getCMSResponse = (response) => {
      if (response.result === "SUCCESS") {
        if (response.data === "success") {
          if (toast.current)
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: " Updated successfully",
            });
        }
        getAllAssets(toast, dispatch, headers);
      } else if (response.result === "FAILED" && response.error) {
        const error = response.error;
        toast.current.show({
          severity: error.severity,
          summary: "Error",
          detail: error.errorMsg ? error.errorMsg : error.summary,
        });
      }
    };
    uploadAssetsApi(formData, headers, dispatch, getCMSResponse);
  };

  const formik = useFormik({
    initialValues: assets,
    // validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="home-new">
      <div className="card">
        <div className="imageFormat mb-4 ">
          <strong>(Image Size Should not exceed 1MB)</strong>
        </div>
        {/* <h2>Home Screen Settings</h2> */}
        <form onSubmit={formik.handleSubmit}>
          {/* Top Header */}
          <div className="grid grid-nogutter align-items-center my-4">
            <div className="col-3">
              <label htmlFor="HOMEN0002" className="cmsLabels">
                Top Header
              </label>
            </div>
            <div className="col-4">
              <div className="card file-upload-section">
                <PR.InputText
                  id="HOMEN0002"
                  name="HOMEN0002"
                  aria-describedby="HOMEN0002"
                  value={formik.values.HOMEN0002}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.HOMEN0002 && formik.errors.HOMEN0002 ? (
                <div className="error-msg">{formik.errors.HOMEN0002}</div>
              ) : null}
            </div>
          </div>

          {/* Top Header Description */}
          <div className="grid grid-nogutter align-items-center mb-4">
            <div className="col-3">
              <label htmlFor="HOMEN0003" className="cmsLabels">
                Top Header Description
              </label>
            </div>
            <div className="col-4">
              <div className="card file-upload-section">
                <PR.InputTextarea
                  autoResize
                  id="HOMEN0003"
                  name="HOMEN0003"
                  aria-describedby="HOMEN0003"
                  value={formik.values.HOMEN0003}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.HOMEN0003 && formik.errors.HOMEN0003 ? (
                <div className="error-msg">{formik.errors.HOMEN0003}</div>
              ) : null}
            </div>
          </div>

          <PR.Divider />
          {/* Mobile App section */}
          <div className="grid grid-nogutter">
            <div className="col-12">
              <label htmlFor="unlockSection">
                <h3 className="mb-3 mt-1">Get our app Section</h3>
              </label>
              <div className="card">
                <div className="grid">
                  <div className="col-6">
                    {/* Header */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-3">
                        <label htmlFor="HOMEN0038" className="cmsLabels">
                          Header
                        </label>
                      </div>
                      <div className="col-9">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="HOMEN0038"
                            name="HOMEN0038"
                            aria-describedby="HOMEN0038"
                            value={formik.values.HOMEN0038}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.HOMEN0038 && formik.errors.HOMEN0038 ? (
                          <div className="error-msg">
                            {formik.errors.HOMEN0038}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* Description */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-3">
                        <label htmlFor="HOMEN0039" className="cmsLabels">
                          Description
                        </label>
                      </div>
                      <div className="col-9">
                        <div className="file-upload-section">
                          <PR.InputTextarea
                            autoResize
                            id="HOMEN0039"
                            name="HOMEN0039"
                            aria-describedby="HOMEN0039"
                            value={formik.values.HOMEN0039}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.HOMEN0039 && formik.errors.HOMEN0039 ? (
                          <div className="error-msg">
                            {formik.errors.HOMEN0039}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* App store */}
                    <div className="col-12">
                      <div className="grid grid-nogutter align-items-center mb-1">
                        <div className="col-3">
                          <label htmlFor="HOMEN0041" className="cmsLabels">
                            APP Store Link
                          </label>
                        </div>
                        <div className="col-9">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="HOMEN0041"
                              name="HOMEN0041"
                              aria-describedby="HOMEN0041"
                              value={formik.values.HOMEN0041}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0041 && formik.errors.HOMEN0041 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0041}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      {/* Seamless Connectivity Phone Image */}
                      <div className="grid grid-nogutter align-items-center mb-4">
                        <div className="col-3">
                          <label htmlFor="HOMEN0074" className="cmsLabels">
                            App Store Image <br />
                            <span className="imageFormat">
                              (png, jpeg, 432W X 572H)
                            </span>
                          </label>
                        </div>
                        <div className="col-9 success-story-image-upload">
                          <div className="file-upload-section shop-custom-width">
                            <PR.InputText
                              type="file"
                              name="HOMEN0074"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setAppStoreImage(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "HOMEN0074",
                                  toast,
                                  dispatch,
                                  headers,
                                  "home-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {appStoreImage &&
                            formik.touched.HOMEN0074 &&
                            formik.errors.HOMEN0074 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0074}
                            </div>
                          ) : null}
                          <ImagePreview
                            formikError={formik.errors.HOMEN0074}
                            defaultImage={assets.HOMEN0074}
                            newImage={appStoreImage}
                          />
                        </div>
                      </div>
                    </div>
                  
                  </div>
                  <div className="col-6">
                    {/* Seamless Connectivity Phone Image */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-3">
                        <label htmlFor="HOMEN0076" className="cmsLabels">
                          Mobile APP Image <br />
                          <span className="imageFormat">
                            (png, jpeg, 432W X 572H)
                          </span>
                        </label>
                      </div>
                      <div className="col-9 success-story-image-upload">
                        <div className="file-upload-section">
                          <PR.InputText
                            type="file"
                            name="HOMEN0076"
                            className="file-upload-input"
                            accept="image/png, image/jpeg"
                            onChange={async (event) => {
                              const file = event.currentTarget.files[0];
                              setMobileImage(file);
                              formik.handleChange(event);
                              uploadWidthHeightImage(
                                file,
                                "HOMEN0076",
                                toast,
                                dispatch,
                                headers,
                                "home-new"
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {mobileImage &&
                          formik.touched.HOMEN0076 &&
                          formik.errors.HOMEN0076 ? (
                          <div className="error-msg">
                            {formik.errors.HOMEN0076}
                          </div>
                        ) : null}
                        <ImagePreview
                          formikError={formik.errors.HOMEN0076}
                          defaultImage={assets.HOMEN0076}
                          newImage={mobileImage}
                        />
                      </div>
                    </div>
                      {/* Play store */}
                      <div className="col-12 mt-6">
                      <div className="grid grid-nogutter align-items-center mb-1">
                        <div className="col-3">
                          <label htmlFor="HOMEN0040" className="cmsLabels">
                            Play Store Link
                          </label>
                        </div>
                        <div className="col-9">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="HOMEN0040"
                              name="HOMEN0040"
                              aria-describedby="HOMEN0040"
                              value={formik.values.HOMEN0040}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0040 && formik.errors.HOMEN0040 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0040}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      </div>
                    <div className="col-12">
                      {/* Seamless Connectivity Phone Image */}
                      <div className="grid grid-nogutter align-items-center mb-4">
                        <div className="col-3">
                          <label htmlFor="HOMEN0075" className="cmsLabels">
                            Play Store Image <br />
                            <span className="imageFormat">
                              (png, jpeg, 432W X 572H)
                            </span>
                          </label>
                        </div>
                        <div className="col-9 success-story-image-upload">
                          <div className="file-upload-section shop-custom-width" style={{width:'71%'}}>
                            <PR.InputText
                              type="file"
                              name="HOMEN0075"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setPlayStoreImage(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "HOMEN0075",
                                  toast,
                                  dispatch,
                                  headers,
                                  "home-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {playStoreImage &&
                            formik.touched.HOMEN0075 &&
                            formik.errors.HOMEN0075 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0075}
                            </div>
                          ) : null}
                          <ImagePreview
                            formikError={formik.errors.HOMEN0075}
                            defaultImage={assets.HOMEN0075}
                            newImage={playStoreImage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PR.Divider />

          {/* Benefit Description */}
          <div className="grid grid-nogutter align-items-center">
            <div className="col-2">
              <label htmlFor="HOMEN0052" className="cmsLabels">
                Benefit Description
              </label>
            </div>
            <div className="col-4">
              <div className="card file-upload-section">
                <PR.InputTextarea
                  autoResize
                  id="HOMEN0052"
                  name="HOMEN0052"
                  aria-describedby="HOMEN0052"
                  value={formik.values.HOMEN0052}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.HOMEN0052 && formik.errors.HOMEN0052 ? (
                <div className="error-msg">{formik.errors.HOMEN0052}</div>
              ) : null}
            </div>
          </div>

          <PR.Divider />
          {/* Features Section */}
          <div className="grid grid-nogutter">
            <div className="col-12">
              <label htmlFor="panelsSection">
                <h3 className="mb-3 mt-1">Features Section</h3>
              </label>
              <div className="card">
                <div className="grid">
                  <div className="col-2"></div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-1</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-2</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-3</h4>
                    </div>
                  </div>
                </div>
                <div className="label-input-wrapper align-items-center">
                  <div className="col-2">
                    <div className="col-12 mb-4">
                      <label htmlFor="panelHeader" className="cmsLabels">
                        Header
                      </label>
                    </div>
                    <div className="col-12 mb-4">
                      <label htmlFor="panelDescription" className="cmsLabels">
                        Description
                      </label>
                    </div>
                    <div className="col-12">
                      <label htmlFor="panel1Image" className="cmsLabels">
                        Image{" "}
                        <span className="imageFormat">
                          (png, jpeg, 53W X 70H)
                        </span>
                      </label>
                    </div>
                  </div>
                  {/* Panel-1 Section */}
                  <div className="col">
                    <div className="panel-card">
                      {/* Panel-1 Header */}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="HOMEN0053"
                              name="HOMEN0053"
                              aria-describedby="HOMEN0053"
                              value={formik.values.HOMEN0053}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0053 &&
                            formik.errors.HOMEN0053 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0053}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-1 Description */}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputTextarea
                              autoResize
                              id="HOMEN0054"
                              name="HOMEN0054"
                              aria-describedby="HOMEN0054"
                              value={formik.values.HOMEN0054}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0054 &&
                            formik.errors.HOMEN0054 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0054}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-1 Image */}
                      <div className="grid grid-nogutter align-items-center">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="HOMEN0077"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setFeatures1(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "HOMEN0077",
                                  toast,
                                  dispatch,
                                  headers,
                                  "home-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {features1 &&
                            formik.touched.HOMEN0077 &&
                            formik.errors.HOMEN0077 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0077}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Panel-2 Section */}
                  <div className="col">
                    <div className="panel-card">
                      {/* Panel-2 Header */}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="HOMEN0055"
                              name="HOMEN0055"
                              aria-describedby="HOMEN0055"
                              value={formik.values.HOMEN0055}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0055 &&
                            formik.errors.HOMEN0055 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0055}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-2 Description */}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputTextarea
                              autoResize
                              id="HOMEN0056"
                              name="HOMEN0056"
                              aria-describedby="HOMEN0056"
                              value={formik.values.HOMEN0056}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0056 &&
                            formik.errors.HOMEN0056 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0056}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-2 Image */}
                      <div className="grid grid-nogutter align-items-center">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="HOMEN0078"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setFeatures2(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "HOMEN0078",
                                  toast,
                                  dispatch,
                                  headers,
                                  "home-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {features2 &&
                            formik.touched.HOMEN0078 &&
                            formik.errors.HOMEN0078 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0078}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Panel-3  */}
                  <div className="col">
                    <div className="panel-card">
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="HOMEN0057"
                              name="HOMEN0057"
                              aria-describedby="HOMEN0057"
                              value={formik.values.HOMEN0057}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0057 &&
                            formik.errors.HOMEN0057 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0057}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-3 Description */}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputTextarea
                              autoResize
                              id="HOMEN0058"
                              name="HOMEN0058"
                              aria-describedby="HOMEN0058"
                              value={formik.values.HOMEN0058}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0058 &&
                            formik.errors.HOMEN0058 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0058}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-3 Image */}
                      <div className="grid grid-nogutter align-items-center">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="HOMEN0079"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setFeatures3(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "HOMEN0079",
                                  toast,
                                  dispatch,
                                  headers,
                                  "home-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {features3 &&
                            formik.touched.HOMEN0079 &&
                            formik.errors.HOMEN0079 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0079}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="label-input-wrapper">
                  <div className="col-2"></div>
                  <div className="col">
                    <div className="w-3 h-4">
                      <div className="col-12 features-section-image-preview">
                        <ImagePreview
                          formikError={formik.errors.HOMEN0077}
                          defaultImage={assets.HOMEN0077}
                          newImage={features1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="w-3 h-4">
                      <div className="col-12 features-section-image-preview">
                        <ImagePreview
                          formikError={formik.errors.HOMEN0078}
                          defaultImage={assets.HOMEN0078}
                          newImage={features2}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className=" w-3 h-4">
                      <div className="col-12 features-section-image-preview">
                        <ImagePreview
                          formikError={formik.errors.HOMEN0079}
                          defaultImage={assets.HOMEN0079}
                          newImage={features3}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PR.Divider />
          {/* Activation Section */}
          <div className="grid grid-nogutter">
            <div className="col-12">
              <label htmlFor="panelsSection">
                <h3 className="mb-3 mt-1">Activation Section</h3>
              </label>
              {/* Header */}
              <div className="grid grid-nogutter align-items-center mb-4">
                <div className="col-2">
                  <label htmlFor="HOMEN0036" className="cmsLabels">
                    Header
                  </label>
                </div>
                <div className="col-5">
                  <div className="card file-upload-section">
                    <PR.InputText
                      id="HOMEN0036"
                      name="HOMEN0036"
                      aria-describedby="HOMEN0036"
                      value={formik.values.HOMEN0036}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.HOMEN0036 && formik.errors.HOMEN0036 ? (
                    <div className="error-msg">{formik.errors.HOMEN0036}</div>
                  ) : null}
                </div>
              </div>
              <div className="card">
                <div className="grid">
                  <div className="col-2"></div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-1</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-2</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-3</h4>
                    </div>
                  </div>
                </div>
                <div className="label-input-wrapper align-items-center">
                  <div className="col-2">
                    <div className="col-12 mb-4">
                      <label htmlFor="panelHeader" className="cmsLabels">
                        Description
                      </label>
                    </div>
                    <div className="col-12">
                      <label htmlFor="panel1Image" className="cmsLabels">
                        Image{" "}
                        <span className="imageFormat">
                          (png, jpeg, 53W X 70H)
                        </span>
                      </label>
                    </div>
                  </div>
                  {/* Panel-1 Section */}
                  <div className="col">
                    <div className="panel-card">
                      {/* Panel-1 Header */}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="HOMEN0019"
                              name="HOMEN0019"
                              aria-describedby="HOMEN0019"
                              value={formik.values.HOMEN0019}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0019 && formik.errors.HOMEN0019 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0019}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Panel-1 Image */}
                      <div className="grid grid-nogutter align-items-center">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="HOMEN0080"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setActivation1(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "HOMEN0080",
                                  toast,
                                  dispatch,
                                  headers,
                                  "home-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {activation1 &&
                            formik.touched.HOMEN0080 &&
                            formik.errors.HOMEN0080 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0080}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Panel-2 Section */}
                  <div className="col">
                    <div className="panel-card">
                      {/* Panel-2 Header */}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="HOMEN0022"
                              name="HOMEN0022"
                              aria-describedby="HOMEN0022"
                              value={formik.values.HOMEN0022}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0022 && formik.errors.HOMEN0022 ? (
                            <div className="error-msg">
                              {formik.errors.HOME0008r}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-2 Image */}
                      <div className="grid grid-nogutter align-items-center">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="HOMEN0081"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setActivation2(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "HOMEN0081",
                                  toast,
                                  dispatch,
                                  headers,
                                  "home-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {activation2 &&
                            formik.touched.HOMEN0081 &&
                            formik.errors.HOMEN0081 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0081}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Panel-3  */}
                  <div className="col">
                    <div className="panel-card">
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="HOMEN0025"
                              name="HOMEN0025"
                              aria-describedby="HOMEN0025"
                              value={formik.values.HOMEN0025}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>

                          {formik.touched.HOMEN0025 && formik.errors.HOMEN0025 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0025}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-3 Image */}
                      <div className="grid grid-nogutter align-items-center">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="HOMEN0082"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setActivation3(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "HOMEN0082",
                                  toast,
                                  dispatch,
                                  headers,
                                  "home-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {activation3 &&
                            formik.touched.HOMEN0082 &&
                            formik.errors.HOMEN0082 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0082}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="label-input-wrapper">
                  <div className="col-2"></div>
                  <div className="col">
                    <div className="grid grid-nogutter">
                      <div className="col-12">
                        <ImagePreview
                          formikError={formik.errors.HOMEN0080}
                          defaultImage={assets.HOMEN0080}
                          newImage={activation1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="grid grid-nogutter">
                      <div className="col-12">
                        <ImagePreview
                          formikError={formik.errors.HOMEN0081}
                          defaultImage={assets.HOMEN0081}
                          newImage={activation2}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="grid grid-nogutter">
                      <div className="col-12">
                        <ImagePreview
                          formikError={formik.errors.HOMEN0082}
                          defaultImage={assets.HOMEN0082}
                          newImage={activation3}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PR.Divider />
          {/* Global Coverage Section */}
          <div className="grid grid-nogutter">
            <div className="col-12">
              <label htmlFor="unlockSection">
                <h3 className="mb-3 mt-1">Global Coverage</h3>
              </label>
              <div className="card">
                <div className="grid">
                  <div className="col-6">
                    {/* Header */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-3">
                        <label htmlFor="HOMEN0015" className="cmsLabels">
                          Header
                        </label>
                      </div>
                      <div className="col-9">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="HOMEN0015"
                            name="HOMEN0015"
                            aria-describedby="HOMEN0015"
                            value={formik.values.HOMEN0015}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.HOMEN0015 && formik.errors.HOMEN0015 ? (
                          <div className="error-msg">
                            {formik.errors.HOMEN0015}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* Description */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-3">
                        <label htmlFor="HOMEN0016" className="cmsLabels">
                          Description
                        </label>
                      </div>
                      <div className="col-9">
                        <div className="file-upload-section">
                          <PR.InputTextarea
                            autoResize
                            id="HOMEN0016"
                            name="HOMEN0016"
                            aria-describedby="HOMEN0016"
                            value={formik.values.HOMEN0016}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.HOMEN0016 && formik.errors.HOMEN0016 ? (
                          <div className="error-msg">
                            {formik.errors.HOMEN0016}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* Button Name */}
                    <div className="grid grid-nogutter align-items-center mb-1">
                      <div className="col-3">
                        <label htmlFor="HOMEN0017" className="cmsLabels">
                          Button Name
                        </label>
                      </div>
                      <div className="col-9">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="HOMEN0017"
                            name="HOMEN0017"
                            aria-describedby="HOMEN0017"
                            value={formik.values.HOMEN0017}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.HOMEN0017 && formik.errors.HOMEN0017 ? (
                          <div className="error-msg">
                            {formik.errors.HOMEN0017}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    {/* Seamless Connectivity Phone Image */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-5">
                        <label htmlFor="HOME0014" className="cmsLabels">
                          Global Coverage Right Image <br />
                          <span className="imageFormat">
                            (png, jpeg, 432W X 572H)
                          </span>
                        </label>
                      </div>
                      <div className="col-7">
                        <div className="file-upload-section">
                          <PR.InputText
                            type="file"
                            name="HOMEN0083"
                            className="file-upload-input"
                            accept="image/png, image/jpeg"
                            onChange={async (event) => {
                              const file = event.currentTarget.files[0];
                              setGlobalCoverage(file);
                              formik.handleChange(event);
                              uploadWidthHeightImage(
                                file,
                                "HOMEN0083",
                                toast,
                                dispatch,
                                headers,
                                "home-new"
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {globalCoverage &&
                          formik.touched.HOMEN0083 &&
                          formik.errors.HOMEN0083 ? (
                          <div className="error-msg">
                            {formik.errors.HOMEN0083}
                          </div>
                        ) : null}
                        <ImagePreview
                          formikError={formik.errors.HOMEN0083}
                          defaultImage={assets.HOMEN0083}
                          newImage={globalCoverage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PR.Divider />

          <div className="file-upload">
            <div>
              <label htmlFor="partnerSection">
                <h3 className="mb-4 mt-1">FAQ Section</h3>
              </label>
              <div className="card">
                <div className="grid">
                  <div className="col-6">
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-3">
                        <label htmlFor="HOMEN0059">
                          <b>Header</b>
                        </label>
                      </div>
                      <div className="col-9">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="HOMEN0059"
                            name="HOMEN0059"
                            aria-describedby="HOMEN0059"
                            value={formik.values.HOMEN0059}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.HOMEN0059 && formik.errors.HOMEN0059 ? (
                          <div className="error-msg">
                            {formik.errors.HOMEN0059}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="grid grid-nogutter align-items-center">
                      <div className="col-3">
                        <label htmlFor="HOMEN0060">
                          <b>Button Name</b>
                        </label>
                      </div>
                      <div className="col-9">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="HOMEN0060"
                            name="HOMEN0060"
                            aria-describedby="HOMEN0060"
                            value={formik.values.HOMEN0060}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.HOMEN0060 && formik.errors.HOMEN0060 ? (
                          <div className="error-msg">
                            {formik.errors.HOMEN0060}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="col-12">
                      <div className="grid grid-nogutter align-items-center mb-4">
                        <div className="col-3">
                          <label htmlFor="HOMEN0084">
                            <b>
                              Right Section Image{" "}
                              <span className="imageFormat">
                                (png, jpeg, 689W X 817H)
                              </span>
                            </b>
                          </label>
                        </div>
                        <div className="col-7">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="HOMEN0084"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setFAQRightImage(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "HOMEN0084",
                                  toast,
                                  dispatch,
                                  headers,
                                  "home-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                            {FAQRightImage &&
                              formik.touched.HOMEN0084 &&
                              formik.errors.HOMEN0084 ? (
                              <div className="error-msg">
                                {formik.errors.HOMEN0084}
                              </div>
                            ) : null}
                            <ImagePreview
                              formikError={formik.errors.HOMEN0084}
                              defaultImage={assets.HOMEN0084}
                              newImage={FAQRightImage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PR.Divider />

          <div className="file-upload">
            <div>
              <label htmlFor="partnerSection">
                <h3 className="mb-4 mt-1">Get in touch Section</h3>
              </label>
              <div className="card">
                <div className="grid">
                  <div className="col-6">
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-3">
                        <label htmlFor="HOMEN0049">
                          <b>Header</b>
                        </label>
                      </div>
                      <div className="col-9">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="HOMEN0049"
                            name="HOMEN0049"
                            aria-describedby="HOMEN0049"
                            value={formik.values.HOMEN0049}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.HOMEN0049 && formik.errors.HOMEN0049 ? (
                          <div className="error-msg">
                            {formik.errors.HOMEN0049}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="grid grid-nogutter align-items-center mb-4">
                        <div className="col-3">
                          <label htmlFor="HOMEN0085">
                            <b>
                              Left Section Image{" "}
                              <span className="imageFormat">
                                (png, jpeg, 689W X 817H)
                              </span>
                            </b>
                          </label>
                        </div>
                        <div className="col-9">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="HOMEN0085"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setgetInTouchImage(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "HOMEN0085",
                                  toast,
                                  dispatch,
                                  headers,
                                  "home-new"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                            {getInTouchImage &&
                              formik.touched.HOMEN0085 &&
                              formik.errors.HOMEN0085 ? (
                              <div className="error-msg">
                                {formik.errors.HOMEN0085}
                              </div>
                            ) : null}
                            <ImagePreview
                              formikError={formik.errors.HOMEN0085}
                              defaultImage={assets.HOMEN0085}
                              newImage={getInTouchImage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-3">
                        <label htmlFor="HOMEN0061">
                          <b>Description</b>
                        </label>
                      </div>
                      <div className="col-9">
                        <div className="file-upload-section">
                          <PR.InputTextarea
                            autoResize
                            id="HOMEN0061"
                            name="HOMEN0061"
                            aria-describedby="HOMEN0061"
                            value={formik.values.HOMEN0061}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.HOMEN0061 && formik.errors.HOMEN0061 ? (
                          <div className="error-msg">
                            {formik.errors.HOMEN0061}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="grid grid-nogutter align-items-center">
                      <div className="col-3">
                        <label htmlFor="HOMEN0062">
                          <b>Button Name</b>
                        </label>
                      </div>
                      <div className="col-9">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="HOMEN0062"
                            name="HOMEN0062"
                            aria-describedby="HOMEN0062"
                            value={formik.values.HOMEN0062}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.HOMEN0062 && formik.errors.HOMEN0062 ? (
                          <div className="error-msg">
                            {formik.errors.HOMEN0062}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PR.Divider />

          <div className="file-upload">
            <div>
              <label htmlFor="partnerSection">
                <h3 className="mb-4 mt-1">Partner Section</h3>
              </label>
              <div className="card">
                <div className="grid">
                  <div className="col-6">
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-5">
                        <label htmlFor="HOME0037">
                          <b>
                            Left Section Image{" "}
                            <span className="imageFormat">
                              (png, jpeg, 689W X 817H)
                            </span>
                          </b>
                        </label>
                      </div>
                      <div className="col-7">
                        <div className="file-upload-section">
                          <PR.InputText
                            type="file"
                            name="HOMEN0086"
                            className="file-upload-input"
                            accept="image/png, image/jpeg"
                            onChange={async (event) => {
                              const file = event.currentTarget.files[0];
                              setPartnerLeftImage(file);
                              formik.handleChange(event);
                              uploadWidthHeightImage(
                                file,
                                "HOMEN0086",
                                toast,
                                dispatch,
                                headers,
                                "home-new"
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {partnerLeftImage &&
                            formik.touched.HOMEN0086 &&
                            formik.errors.HOMEN0086 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0086}
                            </div>
                          ) : null}
                          <ImagePreview
                            formikError={formik.errors.HOMEN0086}
                            defaultImage={assets.HOMEN0086}
                            newImage={partnerLeftImage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="grid align-items-center mb-4">
                      <div className="col-3">
                        <label htmlFor="HOMEN0063">
                          <b>Title</b>
                        </label>
                      </div>
                      <div className="col-9">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="HOMEN0063"
                            name="HOMEN0063"
                            aria-describedby="HOMEN0063"
                            value={formik.values.HOMEN0063}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.HOMEN0063 && formik.errors.HOMEN0063 ? (
                          <div className="error-msg">
                            {formik.errors.HOMEN0063}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="grid align-items-center mb-4">
                      <div className="col-3">
                        <label htmlFor="HOMEN0064">
                          <b>Sub Title</b>
                        </label>
                      </div>
                      <div className="col-9">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="HOMEN0064"
                            name="HOMEN0064"
                            aria-describedby="HOMEN0064"
                            value={formik.values.HOMEN0064}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.HOMEN0064 && formik.errors.HOMEN0064 ? (
                          <div className="error-msg">
                            {formik.errors.HOMEN0064}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PR.Divider />
          <div className="grid grid-nogutter">
            <div className="col-12">
              <label htmlFor="panelsSection">
                <h3 className="mb-3 mt-1">Contact Section</h3>
              </label>
              <div className="card">
                <div className="grid">
                  <div className="col-2"></div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-1</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-2</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-3</h4>
                    </div>
                  </div>
                </div>
                <div className="label-input-wrapper align-items-center">
                  <div className="col-2">
                    <div className="col-12 mb-4">
                      <label htmlFor="panelHeader" className="cmsLabels">
                        Header
                      </label>
                    </div>
                    <div className="col-12 mb-4">
                      <label htmlFor="panelDescription" className="cmsLabels">
                        Description
                      </label>
                    </div>
                    <div className="col-12">
                      <label htmlFor="panel1Image" className="cmsLabels">
                        Contact Link
                      </label>
                    </div>
                  </div>
                  {/* Panel-1 Section */}
                  <div className="col">
                    <div className="panel-card">
                      {/* Panel-1 Header */}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="HOMEN0065"
                              name="HOMEN0065"
                              aria-describedby="HOMEN0065"
                              value={formik.values.HOMEN0065}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0065 && formik.errors.HOMEN0065 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0065}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-1 Description */}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputTextarea
                              autoResize
                              id="HOMEN0066"
                              name="HOMEN0066"
                              aria-describedby="HOMEN0066"
                              value={formik.values.HOMEN0066}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="contact-description"
                            />
                          </div>
                          {formik.touched.HOMEN0066 && formik.errors.HOMEN0066 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0066}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-1 Contact Link */}
                      <div className="grid grid-nogutter align-items-center mb-0">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="HOMEN0067"
                              name="HOMEN0067"
                              aria-describedby="HOMEN0067"
                              value={formik.values.HOMEN0067}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0067 && formik.errors.HOMEN0067 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0067}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Panel-2 Section */}
                  <div className="col">
                    <div className="panel-card">
                      {/* Panel-2 Header */}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="HOMEN0068"
                              name="HOMEN0068"
                              aria-describedby="HOMEN0068"
                              value={formik.values.HOMEN0068}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0068 && formik.errors.HOMEN0068 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0068}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-2 Description */}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputTextarea
                              autoResize
                              id="HOMEN0069"
                              name="HOMEN0069"
                              aria-describedby="HOMEN0069"
                              value={formik.values.HOMEN0069}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="contact-description"
                            />
                          </div>
                          {formik.touched.HOMEN0069 && formik.errors.HOMEN0069 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0069}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-2 Contact Link */}
                      <div className="grid grid-nogutter align-items-center mb-0">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="HOMEN0070"
                              name="HOMEN0070"
                              aria-describedby="HOMEN0070"
                              value={formik.values.HOMEN0070}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0070 && formik.errors.HOMEN0070 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0070}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Panel-3  */}
                  <div className="col">
                    <div className="panel-card">
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="HOMEN0071"
                              name="HOMEN0071"
                              aria-describedby="HOMEN0071"
                              value={formik.values.HOMEN0071}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0071 && formik.errors.HOMEN0071 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0071}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-3 Description */}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputTextarea
                              autoResize
                              id="HOMEN0072"
                              name="HOMEN0072"
                              aria-describedby="HOMEN0072"
                              value={formik.values.HOMEN0072}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="contact-description"
                            />
                          </div>
                          {formik.touched.HOMEN0072 && formik.errors.HOMEN0072 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0072}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-3 Contact Link */}
                      <div className="grid grid-nogutter align-items-center mb-0">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="HOMEN0073"
                              name="HOMEN0073"
                              aria-describedby="HOMEN0073"
                              value={formik.values.HOMEN0073}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.HOMEN0073 && formik.errors.HOMEN0073 ? (
                            <div className="error-msg">
                              {formik.errors.HOMEN0073}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PR.Divider />
          <div className="col-9">
            <PR.Button
              label="Save"
              type="submit"
              className="saveBtn mt-2"
              disabled={disableSave}
            />
          </div>
        </form>
      </div>
      <PR.Toast ref={toast} position="top-right" />
    </div>
  );
};
export default Shop;